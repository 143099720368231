<ctx-algolia-content-thumbnail [content]="file" />

<div class="global-search-file__information">
	<p class="margin-0">
		<tui-icon icon="@tui.sprout" />
		{{ file.name }}
	</p>

	<small class="margin-0">
		{{
			'data.last-edited-at'
				| transloco: { time: updatedAt | relativeTime }
		}}
	</small>
</div>
