import { AsyncPipe } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Injectable, Directive } from '@angular/core';
import { tuiSlideInTop, tuiFadeIn } from '@taiga-ui/core/animations';
import { TuiButton } from '@taiga-ui/core/components/button';
import { TUI_ANIMATIONS_SPEED, TUI_CLOSE_WORD, TUI_COMMON_ICONS } from '@taiga-ui/core/tokens';
import { tuiToAnimationOptions } from '@taiga-ui/core/utils/miscellaneous';
import { injectContext, PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
import { TuiPopoverDirective } from '@taiga-ui/cdk/directives/popover';
import { TuiPopoverService, tuiAsPopover } from '@taiga-ui/cdk/services';
import { TUI_DIALOGS } from '@taiga-ui/core/components/dialog';
import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk/utils/miscellaneous';
function TuiPdfViewerComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
function TuiPdfViewerComponent_iframe_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "iframe", 7);
  }
  if (rf & 2) {
    const content_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("src", content_r2, i0.ɵɵsanitizeResourceUrl);
  }
}
class TuiPdfViewerComponent {
  constructor() {
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.closeWord$ = inject(TUI_CLOSE_WORD);
    this.icons = inject(TUI_COMMON_ICONS);
    this.context = injectContext();
  }
  onKeyDownEsc() {
    this.context.$implicit.complete();
  }
  static {
    this.ɵfac = function TuiPdfViewerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiPdfViewerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiPdfViewerComponent,
      selectors: [["tui-pdf-viewer"]],
      hostVars: 2,
      hostBindings: function TuiPdfViewerComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown.esc", function TuiPdfViewerComponent_keydown_esc_HostBindingHandler() {
            return ctx.onKeyDownEsc();
          }, false, i0.ɵɵresolveDocument);
        }
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@tuiFadeIn", ctx.options)("@tuiSlideInTop", ctx.options);
        }
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 10,
      vars: 11,
      consts: [[1, "t-header"], ["automation-id", "tui-pdf-viewer__label", 1, "t-title"], [1, "t-actions"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["appearance", "", "size", "s", "tuiIconButton", "", "type", "button", 1, "t-close", 3, "click", "iconStart"], [1, "t-content"], ["title", "pdf", "class", "t-iframe", 3, "src", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["title", "pdf", 1, "t-iframe", 3, "src"]],
      template: function TuiPdfViewerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "header", 0)(1, "h2", 1);
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 2);
          i0.ɵɵtemplate(4, TuiPdfViewerComponent_ng_container_4_Template, 2, 1, "ng-container", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "button", 4);
          i0.ɵɵlistener("click", function TuiPdfViewerComponent_Template_button_click_5_listener() {
            return ctx.context.$implicit.complete();
          });
          i0.ɵɵtext(6);
          i0.ɵɵpipe(7, "async");
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(8, "section", 5);
          i0.ɵɵtemplate(9, TuiPdfViewerComponent_iframe_9_Template, 1, 1, "iframe", 6);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", ctx.context.label, " ");
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("polymorpheusOutlet", ctx.context.actions)("polymorpheusOutletContext", ctx.context);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("border-radius", 100, "%");
          i0.ɵɵproperty("iconStart", ctx.icons.close);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(7, 9, ctx.closeWord$), " ");
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("polymorpheusOutlet", ctx.context.content)("polymorpheusOutletContext", ctx.context);
        }
      },
      dependencies: [AsyncPipe, PolymorpheusOutlet, TuiButton],
      styles: ["[_nghost-%COMP%]{display:block;inline-size:100%;block-size:100%;box-sizing:border-box;color:#fff;background:#333639}.t-header[_ngcontent-%COMP%]{display:flex;align-items:center;block-size:4rem;padding:0 1rem 0 1.5625rem;box-shadow:inset 0 -1px #535659}.t-title[_ngcontent-%COMP%]{margin:0;font:var(--tui-font-text-m);white-space:nowrap;text-overflow:ellipsis;padding-right:.3125rem;overflow:hidden}.t-actions[_ngcontent-%COMP%]{display:flex;margin-left:auto}.t-close[_ngcontent-%COMP%]{transition-property:background;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;margin-left:.75rem;color:#fff;background:#ffffff52}.t-close[_ngcontent-%COMP%]:hover{background:#fff6}.t-content[_ngcontent-%COMP%]{block-size:calc(100% - 4rem);overflow:hidden}.t-iframe[_ngcontent-%COMP%]{inline-size:100%;block-size:100%}"],
      data: {
        animation: [tuiSlideInTop, tuiFadeIn]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPdfViewerComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-pdf-viewer',
      imports: [AsyncPipe, PolymorpheusOutlet, PolymorpheusTemplate, TuiButton],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiSlideInTop, tuiFadeIn],
      host: {
        '[@tuiFadeIn]': 'options',
        '[@tuiSlideInTop]': 'options',
        '(document:keydown.esc)': 'onKeyDownEsc()'
      },
      template: "<header class=\"t-header\">\n    <h2\n        automation-id=\"tui-pdf-viewer__label\"\n        class=\"t-title\"\n    >\n        {{ context.label }}\n    </h2>\n    <div class=\"t-actions\">\n        <ng-container *polymorpheusOutlet=\"context.actions as text; context: context\">\n            {{ text }}\n        </ng-container>\n    </div>\n    <button\n        appearance=\"\"\n        size=\"s\"\n        tuiIconButton\n        type=\"button\"\n        class=\"t-close\"\n        [iconStart]=\"icons.close\"\n        [style.border-radius.%]=\"100\"\n        (click)=\"context.$implicit.complete()\"\n    >\n        {{ closeWord$ | async }}\n    </button>\n</header>\n<section class=\"t-content\">\n    <iframe\n        *polymorpheusOutlet=\"context.content as content; context: context\"\n        title=\"pdf\"\n        class=\"t-iframe\"\n        [src]=\"content\"\n    ></iframe>\n</section>\n",
      styles: [":host{display:block;inline-size:100%;block-size:100%;box-sizing:border-box;color:#fff;background:#333639}.t-header{display:flex;align-items:center;block-size:4rem;padding:0 1rem 0 1.5625rem;box-shadow:inset 0 -1px #535659}.t-title{margin:0;font:var(--tui-font-text-m);white-space:nowrap;text-overflow:ellipsis;padding-right:.3125rem;overflow:hidden}.t-actions{display:flex;margin-left:auto}.t-close{transition-property:background;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;margin-left:.75rem;color:#fff;background:#ffffff52}.t-close:hover{background:#fff6}.t-content{block-size:calc(100% - 4rem);overflow:hidden}.t-iframe{inline-size:100%;block-size:100%}\n"]
    }]
  }], null, null);
})();
const TUI_PDF_VIEWER_DEFAULT_OPTIONS = {
  label: '',
  actions: '',
  data: undefined
};
/**
 * Default parameters for PdfViewer component
 */
const TUI_PDF_VIEWER_OPTIONS = tuiCreateToken(TUI_PDF_VIEWER_DEFAULT_OPTIONS);
function tuiPdfViewerOptionsProvider(options) {
  return tuiProvideOptions(TUI_PDF_VIEWER_OPTIONS, options, TUI_PDF_VIEWER_DEFAULT_OPTIONS);
}
class TuiPdfViewerService extends TuiPopoverService {
  open(content, options = {}) {
    return super.open(content, options);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiPdfViewerService_BaseFactory;
      return function TuiPdfViewerService_Factory(__ngFactoryType__) {
        return (ɵTuiPdfViewerService_BaseFactory || (ɵTuiPdfViewerService_BaseFactory = i0.ɵɵgetInheritedFactory(TuiPdfViewerService)))(__ngFactoryType__ || TuiPdfViewerService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiPdfViewerService,
      factory: () => (() => new TuiPdfViewerService(TUI_DIALOGS, TuiPdfViewerComponent, inject(TUI_PDF_VIEWER_OPTIONS)))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPdfViewerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => new TuiPdfViewerService(TUI_DIALOGS, TuiPdfViewerComponent, inject(TUI_PDF_VIEWER_OPTIONS))
    }]
  }], null, null);
})();
class TuiPdfViewerDirective extends TuiPopoverDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiPdfViewerDirective_BaseFactory;
      return function TuiPdfViewerDirective_Factory(__ngFactoryType__) {
        return (ɵTuiPdfViewerDirective_BaseFactory || (ɵTuiPdfViewerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiPdfViewerDirective)))(__ngFactoryType__ || TuiPdfViewerDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiPdfViewerDirective,
      selectors: [["ng-template", "tuiPdfViewer", ""]],
      inputs: {
        options: [0, "tuiPdfViewerOptions", "options"],
        open: [0, "tuiPdfViewer", "open"]
      },
      outputs: {
        openChange: "tuiPdfViewerChange"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAsPopover(TuiPdfViewerService)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPdfViewerDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-template[tuiPdfViewer]',
      inputs: ['options: tuiPdfViewerOptions', 'open: tuiPdfViewer'],
      outputs: ['openChange: tuiPdfViewerChange'],
      providers: [tuiAsPopover(TuiPdfViewerService)]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_PDF_VIEWER_DEFAULT_OPTIONS, TUI_PDF_VIEWER_OPTIONS, TuiPdfViewerComponent, TuiPdfViewerDirective, TuiPdfViewerService, tuiPdfViewerOptionsProvider };
