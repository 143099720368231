<div class="mobile-menu-dialog__header">
	<img draggable="false" src="assets/images/wordmark-white.png" />
	<button
		class="secondary"
		tuiIconButton
		appearance="ghost"
		size="m"
		(click)="onDismiss()"
	>
		<tui-icon size="l" icon="@tui.x"></tui-icon>
	</button>
</div>

<header tuiHeader class="mobile-menu-dialog__content-profile">
	<ctx-avatar
		[routerLink]="'profile'"
		(click)="onDismiss()"
		size="xl"
		[user]="authService.user"
	></ctx-avatar>

	<div class="mobile-menu-dialog__content-profile-info">
		<p class="color-primary margin-v-0 font-size-xlarge font-bold">
			{{ authService.user | userName }}
		</p>
		<p class="color-tertiary margin-v-0 font-size-small">
			{{ authService.organization?.name }}
		</p>
	</div>
</header>

<section tuiContent>
	@for (item of context.data.menuItems; track item.key) {
		@if (
			(item.location === 'side' || item.location === 'all') &&
			(!item.requiredRole || (item.requiredRole | roleAccess))
		) {
			@if (item.handler) {
				<button
					tuiLink
					size="l"
					class="menu-button"
					[iconStart]="item.icon"
					(click)="onClick(item)"
				>
					{{ 'navigation.' + item.key | transloco }}
				</button>
			} @else {
				<a
					tuiLink
					size="l"
					class="menu-button"
					[iconStart]="item.icon"
					[routerLink]="[item.route]"
					routerLinkActive="active"
					(click)="onDismiss(item)"
				>
					{{ 'navigation.' + item.key | transloco }}
				</a>
			}
		}
	}
</section>
