<search tuiSearch>
	<form [formGroup]="search">
		<fieldset tuiTextfieldSize="m">
			<tui-textfield iconStart="@tui.search">
				<input
					formControlName="term"
					[placeholder]="'data.global-search-placeholder' | transloco"
					tuiTextfield
					[tuiDropdown]="globalSearchDropdown"
					[(tuiDropdownOpen)]="dropdownOpen"
				/>
			</tui-textfield>
		</fieldset>
	</form>
</search>

<ng-template #globalSearchDropdown>
	<ctx-global-search-dropdown
		[loading]="loading"
		[data]="data"
		[totalElements]="totalElements"
		(select)="onClose()"
	/>
</ng-template>
