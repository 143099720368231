@if (loading) {
	<tui-loader size="l" />
} @else if (data.length) {
	@for (item of data; track item.objectID) {
		@switch (item.type) {
			@case ('user') {
				@if (['/users/', item.objectID, 'content']; as path) {
					@if ('admin' | roleAccess) {
						<ctx-global-search-user
							[user]="item"
							[tabindex]="0"
							(click)="onSelect(path)"
							(keyup.enter)="onSelect(path)"
						/>
					} @else {
						<ctx-global-search-user [user]="item" />
					}
				}
			}
			@case ('file') {
				@if (['/home/file/', item.objectID]; as path) {
					<ctx-global-search-file
						[file]="item"
						tabindex="0"
						(click)="onSelect(path)"
						(keyup.enter)="onSelect(path)"
					/>
				}
			}
			@case ('folder') {
				@if (['/home/folder/', item.objectID]; as path) {
					<ctx-global-search-folder
						[folder]="item"
						tabindex="0"
						(click)="onSelect(path)"
						(keyup.enter)="onSelect(path)"
					/>
				}
			}
			@case ('media') {
				@if (
					['/home/file/', item.metadata.parentId, 'media'];
					as path
				) {
					<ctx-global-search-media
						[media]="item"
						tabindex="0"
						(click)="onSelect(path)"
						(keyup.enter)="onSelect(path)"
					/>
				}
			}
		}
	}

	<div class="global-search-dropdown__footer">
		<p class="margin-0">
			{{
				'pagination.showing-of'
					| transloco: { count: data.length, total: totalElements }
			}}
		</p>
	</div>
} @else {
	<ctx-table-placeholder
		[header]="'data.search-placeholder-header' | transloco"
		[content]="'data.search-placeholder-content' | transloco"
		icon="@tui.search"
	></ctx-table-placeholder>
}
