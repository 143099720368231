import * as i0 from '@angular/core';
import { inject, Injectable, Directive, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { tuiTypedFromEvent } from '@taiga-ui/cdk/observables';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { Observable, merge, switchMap, filter, map, pairwise, takeUntil, repeat } from 'rxjs';
class TuiPanService extends Observable {
  constructor() {
    const el = tuiInjectElement();
    const doc = inject(DOCUMENT);
    super(subscriber => merge(tuiTypedFromEvent(el, 'touchstart', {
      passive: true
    }), tuiTypedFromEvent(el, 'mousedown')).pipe(switchMap(() => merge(tuiTypedFromEvent(doc, 'touchmove', {
      passive: true
    }).pipe(filter(({
      touches
    }) => touches.length < 2), map(({
      touches
    }) => touches[0])), tuiTypedFromEvent(doc, 'mousemove'))), pairwise(), map(([first, second]) => {
      const deltaX = (second?.clientX ?? 0) - (first?.clientX ?? 0);
      const deltaY = (second?.clientY ?? 0) - (first?.clientY ?? 0);
      return [deltaX, deltaY];
    }), takeUntil(merge(tuiTypedFromEvent(doc, 'touchend'), tuiTypedFromEvent(doc, 'mouseup'))), repeat()).subscribe(subscriber));
  }
  static {
    this.ɵfac = function TuiPanService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiPanService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiPanService,
      factory: TuiPanService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPanService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class TuiPan {
  constructor() {
    this.tuiPan = inject(TuiPanService);
  }
  static {
    this.ɵfac = function TuiPan_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiPan)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiPan,
      selectors: [["", "tuiPan", ""]],
      outputs: {
        tuiPan: "tuiPan"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiPanService])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPan, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiPan]',
      providers: [TuiPanService]
    }]
  }], null, {
    tuiPan: [{
      type: Output
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPan, TuiPanService };
