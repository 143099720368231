import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { getUaDevice } from '@context/frontend/native/platform';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiLoader } from '@taiga-ui/core';
import { TuiPreview } from '@taiga-ui/kit';

@Component({
	standalone: true,
	imports: [
		TuiPreview,
		TranslocoModule,
		TuiButton,
		TuiLoader,
	],
	selector: 'ctx-document-preview-dialog',
	templateUrl: 'document-preview-dialog.component.html',
	styleUrl: 'document-preview-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewDialogComponent extends DialogBase<
	void,
	{ safeUrl: SafeUrl; label: string }
> {
	static override readonly DialogOptions = {
		size: 'fullscreen',
	} as DialogOptions;

	@HostBinding('attr.device')
	readonly device = getUaDevice();
}
