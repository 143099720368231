<ctx-algolia-avatar [user]="user" />

<div class="global-search-user__information">
	<p class="margin-0">
		<tui-icon icon="@tui.user" />
		@if (user.name) {
			{{ user.name }}
		} @else {
			{{ user.metadata.email }}
		}
	</p>

	<small class="margin-0">
		{{ 'user.roles.' + user.metadata.role | transloco }}
		<span class="margin-h-1">•</span>
		{{ user.metadata.email }}
	</small>
</div>
