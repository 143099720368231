import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { GetPathUrlPipe } from '@context/frontend/storage';
import { ContentMedia } from '@context/shared/types/media';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiSwipe } from '@taiga-ui/cdk/directives/swipe';
import { TuiButton, TuiLoader } from '@taiga-ui/core';
import { TuiPreview, TuiPreviewDialogDirective } from '@taiga-ui/kit';
import { PolymorpheusOutlet } from '@taiga-ui/polymorpheus';

@Component({
	standalone: true,
	imports: [
		TuiPreview,
		TranslocoModule,
		PolymorpheusOutlet,
		TuiButton,
		TuiSwipe,
		TuiPreviewDialogDirective,
		GetPathUrlPipe,
		AsyncPipe,
		TuiLoader,
	],
	selector: 'ctx-image-preview-dialog',
	templateUrl: 'image-preview-dialog.component.html',
	styleUrl: 'image-preview-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewDialogComponent extends DialogBase<
	void,
	{ content: ContentMedia }
> {
	static override readonly DialogOptions = {
		size: 'fullscreen',
	} as DialogOptions;
}
