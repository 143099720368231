import { inject, Injectable } from '@angular/core';
import { ORGANIZATION_ID, USER } from '@context/frontend/common';
import { access, AlgoliaGlobal } from '@context/shared/types/search';
import { algoliasearch, Algoliasearch } from 'algoliasearch';
import { ALGOLIA } from '../tokens';
import { hasInheritedRolePermission } from '@context/shared/types/user';

@Injectable({ providedIn: 'root' })
export class GlobalSearchService {
	static readonly IndexName = 'global_search';

	private readonly algolia = inject(ALGOLIA);
	private readonly organizationId = inject(ORGANIZATION_ID);
	private readonly user = inject(USER);

	private _client!: Algoliasearch;

	private get client() {
		if (!this._client) {
			this._client = algoliasearch(
				this.algolia.applicationId,
				this.algolia.apiKey,
			);
		}
		return this._client;
	}

	fetch = (query: string) => {
		const user = this.user.value;
		if (!user) throw Error('No authenticated user was found');

		const isOwnerPlus = hasInheritedRolePermission(user.role, 'owner');
		return this.client
			.search<AlgoliaGlobal>({
				requests: [
					{
						query,
						indexName: GlobalSearchService.IndexName,
						tagFilters: !isOwnerPlus
							? [[access('user', user.id), access('all')]]
							: [],
						facetFilters: [
							// only allow fetching from the same organization
							`organization:${this.organizationId.value}`,
						],
					},
				],
			})
			.then(({ results }) => results[0]);
	};
}
