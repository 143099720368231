import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RelativeTimePipe } from '@context/frontend/common';
import { AlgoliaContentThumbnailComponent } from '@context/frontend/content';
import { AlgoliaContent } from '@context/shared/types/search';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiIcon } from '@taiga-ui/core';

@Component({
	standalone: true,
	imports: [
		AlgoliaContentThumbnailComponent,
		TranslocoModule,
		RelativeTimePipe,
		TuiIcon,
	],
	selector: 'ctx-global-search-file',
	templateUrl: 'global-search-file.component.html',
	styleUrl: 'global-search-file.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchFileComponent {
	@Input() file!: AlgoliaContent;

	get updatedAt() {
		return new Date(this.file.updatedAt);
	}
}
