<ctx-algolia-content-thumbnail [content]="folder" />

<div class="global-search-folder__information">
	<p class="margin-0">
		<tui-icon icon="@tui.folder" />
		{{ folder.name }}
	</p>

	<small class="margin-0">
		{{
			'data.last-edited-at'
				| transloco: { time: updatedAt | relativeTime }
		}}
	</small>
</div>
