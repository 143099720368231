<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<section tuiContent>
	@if (form) {
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<label tuiLabel>
				{{ 'data.name' | transloco }}*

				<span class="media-edit__name-container">
					<tui-textfield tuiTextfieldSize="m">
						<input
							formControlName="name"
							tuiTextfield
							type="text"
							[placeholder]="
								'media.media-name-placeholder' | transloco
							"
						/>
					</tui-textfield>
					<small>
						{{ extension }}
					</small>
				</span>

				<tui-error
					formControlName="name"
					[error]="['required'] | tuiFieldError | async"
				/>
			</label>

			<label tuiLabel>
				{{ 'content.owner' | transloco }}*

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="createdBy"
						tuiTextfield
						type="text"
					/>
				</tui-textfield>
			</label>

			<div class="margin-top-2">
				<label class="font-size-small">
					{{ 'data.media' | transloco }}*
				</label>

				<label class="margin-bottom-2" tuiInputFiles>
					<input
						#validator="tuiInputFilesValidator"
						tuiInputFiles
						[accept]="existingFile?.type ?? acceptedFiles"
						formControlName="file"
					/>
				</label>

				<tui-files class="margin-top-1">
					@if (form.get('file')?.value; as file) {
						<tui-file
							[state]="busy ? 'loading' : 'normal'"
							[leftContent]="!busy ? idle : null"
							[showDelete]="!busy"
							[file]="
								(file | tuiFileRejected: validator | async) ||
								file
							"
							(remove)="onFileRemove()"
						>
							<small>
								{{ 'media.waiting-for-upload' | transloco }}
							</small>
						</tui-file>
					}

					@if (existingFile && existingFile.state === 'deleted') {
						<tui-file
							*tuiItem
							state="deleted"
							[file]="existingFile"
							(remove)="onRemovedFileRestore()"
						>
							<small>
								{{ 'media.removing-media-item' | transloco }}
							</small>
						</tui-file>
					} @else if (existingFile) {
						<tui-file
							*tuiItem
							[leftContent]="existing"
							[file]="existingFile"
							(remove)="onExistingFileRemove()"
						>
							<small>
								{{ 'media.existing-media-item' | transloco }}
							</small>
						</tui-file>
					}
				</tui-files>

				<tui-error
					formControlName="file"
					[error]="['required'] | tuiFieldError | async"
				/>
			</div>
		</form>
	}
</section>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.cancel' | transloco }}
	</button>

	<button tuiButton appearance="accent" (click)="onSubmit()" [loading]="busy">
		{{ 'action.save' | transloco }}
	</button>
</footer>

<ng-template #idle>
	<tui-icon icon="@tui.clock" />
</ng-template>

<ng-template #existing>
	<tui-icon [style.color]="'var(--tui-status-positive)'" icon="@tui.cloud" />
</ng-template>
