import { NgTemplateOutlet } from '@angular/common';
import {
	Component,
	HostBinding,
	Input
} from '@angular/core';
import { getUaDevice } from '@context/frontend/native/platform';

@Component({
	standalone: true,
	imports: [NgTemplateOutlet],
	selector: 'ctx-page-template',
	templateUrl: 'page-template.component.html',
	styleUrl: 'page-template.component.scss',
})
export class PageTemplateComponent {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	/**
	 * the mode of the page template, this determines how the scroll behavior
	 * should be given what the expected contents of the page will be.
	 *
	 * detail - if the template should handle scrolling
	 * table - if the template will offload the scrolling to the table element
	 * and the table element will grow to be the size of the page
	 */
	@HostBinding('attr.mode')
	@Input()
	mode: 'detail' | 'table' = 'detail';
}
