import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, inject, Directive, ContentChild, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as i1 from '@taiga-ui/cdk/directives/native-validator';
import { TuiNativeValidator } from '@taiga-ui/cdk/directives/native-validator';
import { tuiCreateToken, tuiProvideOptions, tuiWithStyles } from '@taiga-ui/cdk/utils/miscellaneous';
import * as i2 from '@taiga-ui/core/directives/appearance';
import { tuiAppearanceOptionsProvider, TuiWithAppearance } from '@taiga-ui/core/directives/appearance';
import * as i3 from '@taiga-ui/core/directives/icons';
import { TuiWithIcons } from '@taiga-ui/core/directives/icons';
import { tuiAvatarOptionsProvider } from '@taiga-ui/kit/components/avatar';
const TUI_BLOCK_DEFAULT_OPTIONS = {
  appearance: 'whiteblock',
  size: 'l'
};
const TUI_BLOCK_OPTIONS = tuiCreateToken(TUI_BLOCK_DEFAULT_OPTIONS);
function tuiBlockOptionsProvider(options) {
  return tuiProvideOptions(TUI_BLOCK_OPTIONS, options, TUI_BLOCK_DEFAULT_OPTIONS);
}
class TuiBlockStyles {
  static {
    this.ɵfac = function TuiBlockStyles_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiBlockStyles)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiBlockStyles,
      selectors: [["ng-component"]],
      hostAttrs: [1, "tui-block"],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function TuiBlockStyles_Template(rf, ctx) {},
      styles: ["[tuiBlock]{--t-height: var(--tui-height-l);--t-radius: var(--tui-radius-l);position:relative;display:inline-flex;gap:.75rem;color:var(--tui-text-primary);border-radius:var(--t-radius);min-block-size:var(--t-height);margin:0;box-sizing:border-box;cursor:pointer;overflow:hidden;font:var(--tui-font-text-m);padding:var(--tui-padding-l)}[tuiBlock][data-size=s]{gap:.5rem;font:var(--tui-font-text-ui-s);padding:.5rem;--t-height: var(--tui-height-s);--t-radius: var(--tui-radius-m)}[tuiBlock][data-size=s] [tuiSubtitle]{font:var(--tui-font-text-ui-xs)}[tuiBlock][data-size=s] [tuiTooltip]{margin:0 .125rem}[tuiBlock][data-size=m]{gap:.625rem;font:var(--tui-font-text-ui-m);padding:var(--tui-padding-m);--t-height: var(--tui-height-m);--t-radius: var(--tui-radius-m)}[tuiBlock][data-size=m] input:not([tuiBlock]){margin:.125rem}[tuiBlock][data-size=m] [tuiTooltip]{margin:.125rem}[tuiBlock]._disabled{pointer-events:none;opacity:var(--tui-disabled-opacity)}[tuiBlock]._disabled :focus{visibility:hidden}[tuiBlock][data-appearance=\"\"]{justify-content:center}[tuiBlock] input[tuiBlock]{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;min-block-size:0;pointer-events:none;border-radius:inherit}[tuiBlock] tui-avatar{margin:-.25rem}[tuiBlock] [tuiTitle]{flex:1;gap:0;font:inherit;color:var(--tui-text-primary)}[tuiBlock] [tuiSubtitle]{color:var(--tui-text-secondary)}[tuiBlock] [tuiTooltip]{vertical-align:bottom;margin:.25rem;font-size:1rem;border:none}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBlockStyles, [{
    type: Component,
    args: [{
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'tui-block'
      },
      styles: ["[tuiBlock]{--t-height: var(--tui-height-l);--t-radius: var(--tui-radius-l);position:relative;display:inline-flex;gap:.75rem;color:var(--tui-text-primary);border-radius:var(--t-radius);min-block-size:var(--t-height);margin:0;box-sizing:border-box;cursor:pointer;overflow:hidden;font:var(--tui-font-text-m);padding:var(--tui-padding-l)}[tuiBlock][data-size=s]{gap:.5rem;font:var(--tui-font-text-ui-s);padding:.5rem;--t-height: var(--tui-height-s);--t-radius: var(--tui-radius-m)}[tuiBlock][data-size=s] [tuiSubtitle]{font:var(--tui-font-text-ui-xs)}[tuiBlock][data-size=s] [tuiTooltip]{margin:0 .125rem}[tuiBlock][data-size=m]{gap:.625rem;font:var(--tui-font-text-ui-m);padding:var(--tui-padding-m);--t-height: var(--tui-height-m);--t-radius: var(--tui-radius-m)}[tuiBlock][data-size=m] input:not([tuiBlock]){margin:.125rem}[tuiBlock][data-size=m] [tuiTooltip]{margin:.125rem}[tuiBlock]._disabled{pointer-events:none;opacity:var(--tui-disabled-opacity)}[tuiBlock]._disabled :focus{visibility:hidden}[tuiBlock][data-appearance=\"\"]{justify-content:center}[tuiBlock] input[tuiBlock]{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;min-block-size:0;pointer-events:none;border-radius:inherit}[tuiBlock] tui-avatar{margin:-.25rem}[tuiBlock] [tuiTitle]{flex:1;gap:0;font:inherit;color:var(--tui-text-primary)}[tuiBlock] [tuiSubtitle]{color:var(--tui-text-secondary)}[tuiBlock] [tuiTooltip]{vertical-align:bottom;margin:.25rem;font-size:1rem;border:none}\n"]
    }]
  }], null, null);
})();
class TuiBlock {
  constructor() {
    this.nothing = tuiWithStyles(TuiBlockStyles);
    this.size = inject(TUI_BLOCK_OPTIONS).size;
  }
  static {
    this.ɵfac = function TuiBlock_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiBlock)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiBlock,
      selectors: [["label", "tuiBlock", ""], ["input", "tuiBlock", ""]],
      contentQueries: function TuiBlock_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgControl, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.control = _t.first);
        }
      },
      hostAttrs: ["tuiBlock", ""],
      hostVars: 3,
      hostBindings: function TuiBlock_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size || "l");
          i0.ɵɵclassProp("_disabled", !!(ctx.control == null ? null : ctx.control.disabled));
        }
      },
      inputs: {
        size: [0, "tuiBlock", "size"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiAppearanceOptionsProvider(TUI_BLOCK_OPTIONS), tuiAvatarOptionsProvider({
        size: 's'
      })]), i0.ɵɵHostDirectivesFeature([i1.TuiNativeValidator, i2.TuiWithAppearance, i3.TuiWithIcons])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBlock, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'label[tuiBlock],input[tuiBlock]',
      providers: [tuiAppearanceOptionsProvider(TUI_BLOCK_OPTIONS), tuiAvatarOptionsProvider({
        size: 's'
      })],
      hostDirectives: [TuiNativeValidator, TuiWithAppearance, TuiWithIcons],
      host: {
        tuiBlock: '',
        '[attr.data-size]': 'size || "l"',
        '[class._disabled]': '!!this.control?.disabled'
      }
    }]
  }], null, {
    control: [{
      type: ContentChild,
      args: [NgControl]
    }],
    size: [{
      type: Input,
      args: ['tuiBlock']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_BLOCK_DEFAULT_OPTIONS, TUI_BLOCK_OPTIONS, TuiBlock, tuiBlockOptionsProvider };
