function m() {
  function r(t) {
    return new Promise(s => {
      let e = new XMLHttpRequest();
      e.open(t.method, t.url, !0), Object.keys(t.headers).forEach(n => e.setRequestHeader(n, t.headers[n]));
      let i = (n, a) => setTimeout(() => {
          e.abort(), s({
            status: 0,
            content: a,
            isTimedOut: !0
          });
        }, n),
        u = i(t.connectTimeout, "Connection timeout"),
        o;
      e.onreadystatechange = () => {
        e.readyState > e.OPENED && o === void 0 && (clearTimeout(u), o = i(t.responseTimeout, "Socket timeout"));
      }, e.onerror = () => {
        e.status === 0 && (clearTimeout(u), clearTimeout(o), s({
          content: e.responseText || "Network request failed",
          status: e.status,
          isTimedOut: !1
        }));
      }, e.onload = () => {
        clearTimeout(u), clearTimeout(o), s({
          content: e.responseText,
          status: e.status,
          isTimedOut: !1
        });
      }, e.send(t.data);
    });
  }
  return {
    send: r
  };
}
export { m as createXhrRequester };
