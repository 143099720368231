import { TuiItem } from '@taiga-ui/cdk/directives/item';
import * as i0 from '@angular/core';
import { signal, Component, ViewEncapsulation, ChangeDetectionStrategy, inject, TemplateRef, ViewChild, computed, ElementRef, ContentChildren, Input } from '@angular/core';
import { tuiButtonOptionsProvider, TuiButton } from '@taiga-ui/core/components/button';
import * as i1$1 from '@taiga-ui/core/components/textfield';
import { tuiTextfieldOptionsProvider, TuiTextfieldOptionsDirective } from '@taiga-ui/core/components/textfield';
import { tuiBlockOptionsProvider } from '@taiga-ui/kit/components/block';
import { tuiSwitchOptionsProvider } from '@taiga-ui/kit/components/switch';
import { NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { ResizeObserverService } from '@ng-web-apis/resize-observer';
import { EMPTY_QUERY } from '@taiga-ui/cdk/constants';
import { tuiZonefull, tuiQueryListChanges, tuiControlValue } from '@taiga-ui/cdk/observables';
import { tuiIsControlEmpty } from '@taiga-ui/cdk/utils/miscellaneous';
import { map, distinctUntilChanged, switchMap, merge } from 'rxjs';
import * as i1 from '@taiga-ui/core/directives/dropdown';
import { tuiDropdown, tuiDropdownOptionsProvider, TuiDropdownDirective, TuiWithDropdownOpen } from '@taiga-ui/core/directives/dropdown';
import { TUI_ICON_START } from '@taiga-ui/core/tokens';
import { TUI_COMMON_ICONS } from '@taiga-ui/layout/tokens';
const _c0 = ["tuiSearch", ""];
const _c1 = ["*"];
const _c2 = ["tuiSearchFilter", ""];
function TuiSearchFilterComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
const _c3 = ["rem"];
function TuiSearchFiltersComponent_ng_container_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 5);
  }
  if (rf & 2) {
    const template_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngTemplateOutlet", template_r1);
  }
}
function TuiSearchFiltersComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiSearchFiltersComponent_ng_container_0_ng_container_1_Template, 1, 1, "ng-container", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i_r2 < ctx_r2.overflown());
  }
}
function TuiSearchFiltersComponent_button_3_ng_container_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 5);
  }
  if (rf & 2) {
    const template_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngTemplateOutlet", template_r5);
  }
}
function TuiSearchFiltersComponent_button_3_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiSearchFiltersComponent_button_3_ng_container_4_ng_container_1_Template, 1, 1, "ng-container", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const i_r6 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i_r6 >= ctx_r2.overflown());
  }
}
function TuiSearchFiltersComponent_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6)(1, "form", 7);
    i0.ɵɵlistener("reset", function TuiSearchFiltersComponent_button_3_Template_form_reset_1_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onReset());
    });
    i0.ɵɵelementStart(2, "fieldset", 8);
    i0.ɵɵprojection(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, TuiSearchFiltersComponent_button_3_ng_container_4_Template, 2, 1, "ng-container", 1);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("_active", ctx_r2.enabled());
    i0.ɵɵproperty("size", ctx_r2.size);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("disabled", !ctx_r2.enabled());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r2.templates);
  }
}
class TuiSearchComponent {
  static {
    this.ɵfac = function TuiSearchComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSearchComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiSearchComponent,
      selectors: [["search", "tuiSearch", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiButtonOptionsProvider({
        size: 'm'
      }), tuiBlockOptionsProvider({
        size: 's'
      }), tuiSwitchOptionsProvider({
        size: 's'
      }), tuiTextfieldOptionsProvider({
        size: signal('m')
      })]), i0.ɵɵStandaloneFeature],
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function TuiSearchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["search[tuiSearch] form{display:flex;flex-direction:column;gap:.75rem}search[tuiSearch] form fieldset{display:flex;align-items:center;flex-wrap:wrap;gap:inherit;min-inline-size:0;padding:0;margin:0;border:0;color:var(--tui-text-secondary)}search[tuiSearch] form tui-items-with-more{min-inline-size:0;flex:1}search[tuiSearch] form tui-textfield{flex:1;min-inline-size:11.25rem;max-inline-size:22.5rem}search[tuiSearch] form tui-textfield:first-child{max-inline-size:unset}search[tuiSearch] form hr{inline-size:1px;block-size:1rem;background:var(--tui-border-normal)}search[tuiSearch] form tui-filter{white-space:nowrap}search[tuiSearch] form [tuiLabel]{font:inherit}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSearchComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'search[tuiSearch]',
      template: '<ng-content/>',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiButtonOptionsProvider({
        size: 'm'
      }), tuiBlockOptionsProvider({
        size: 's'
      }), tuiSwitchOptionsProvider({
        size: 's'
      }), tuiTextfieldOptionsProvider({
        size: signal('m')
      })],
      styles: ["search[tuiSearch] form{display:flex;flex-direction:column;gap:.75rem}search[tuiSearch] form fieldset{display:flex;align-items:center;flex-wrap:wrap;gap:inherit;min-inline-size:0;padding:0;margin:0;border:0;color:var(--tui-text-secondary)}search[tuiSearch] form tui-items-with-more{min-inline-size:0;flex:1}search[tuiSearch] form tui-textfield{flex:1;min-inline-size:11.25rem;max-inline-size:22.5rem}search[tuiSearch] form tui-textfield:first-child{max-inline-size:unset}search[tuiSearch] form hr{inline-size:1px;block-size:1rem;background:var(--tui-border-normal)}search[tuiSearch] form tui-filter{white-space:nowrap}search[tuiSearch] form [tuiLabel]{font:inherit}\n"]
    }]
  }], null, null);
})();
class TuiSearchFilterComponent {
  constructor() {
    this.dropdown = tuiDropdown(null);
  }
  set template(template) {
    this.dropdown.set(template);
  }
  static {
    this.ɵfac = function TuiSearchFilterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSearchFilterComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiSearchFilterComponent,
      selectors: [["button", "tuiSearchFilter", ""]],
      viewQuery: function TuiSearchFilterComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TemplateRef, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
        }
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiDropdownOptionsProvider({
        align: 'right'
      }), {
        provide: TUI_ICON_START,
        useFactory: () => inject(TUI_COMMON_ICONS).filter
      }]), i0.ɵɵHostDirectivesFeature([i1.TuiDropdownDirective, i1.TuiWithDropdownOpen]), i0.ɵɵStandaloneFeature],
      attrs: _c2,
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function TuiSearchFilterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, TuiSearchFilterComponent_ng_template_0_Template, 1, 0, "ng-template");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSearchFilterComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'button[tuiSearchFilter]',
      template: '<ng-template><ng-content /></ng-template>',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiDropdownOptionsProvider({
        align: 'right'
      }), {
        provide: TUI_ICON_START,
        useFactory: () => inject(TUI_COMMON_ICONS).filter
      }],
      hostDirectives: [TuiDropdownDirective, TuiWithDropdownOpen]
    }]
  }], null, {
    template: [{
      type: ViewChild,
      args: [TemplateRef]
    }]
  });
})();
const WIDTH = 12;
class TuiSearchFiltersComponent {
  constructor() {
    this.children = EMPTY_QUERY;
    this.controls = signal([]);
    this.templates = EMPTY_QUERY;
    this.overflown = toSignal(inject(ResizeObserverService, {
      self: true
    }).pipe(map(entry => {
      const width = entry[0]?.contentRect.width ?? 0;
      return Math.floor((width - this.more) / WIDTH / this.unit);
    }), distinctUntilChanged(), tuiZonefull()), {
      initialValue: 0
    });
    this.enabled = computed(() => this.controls().some(({
      control
    }, index) => control && index >= this.overflown() && !tuiIsControlEmpty(control)));
    this.size = 'm';
  }
  // TODO: Refactor to signal queries when Angular is updated
  ngAfterContentInit() {
    tuiQueryListChanges(this.children).pipe(switchMap(all => merge(...all.map(c => tuiControlValue(c)))), map(() => this.children.toArray())).subscribe(controls => this.controls.set(controls));
  }
  onReset() {
    this.children.forEach(({
      control
    }, index) => {
      if (control && index >= this.overflown()) {
        control.setValue(null);
      }
    });
  }
  get unit() {
    return this.rem?.nativeElement.offsetWidth || 16;
  }
  get more() {
    return this.button?.nativeElement.clientWidth || 0;
  }
  static {
    this.ɵfac = function TuiSearchFiltersComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSearchFiltersComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiSearchFiltersComponent,
      selectors: [["tui-search-filters"]],
      contentQueries: function TuiSearchFiltersComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, NgControl, 5);
          i0.ɵɵcontentQuery(dirIndex, TuiItem, 5, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
        }
      },
      viewQuery: function TuiSearchFiltersComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 5);
          i0.ɵɵviewQuery(TuiButton, 5, ElementRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rem = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.button = _t.first);
        }
      },
      inputs: {
        size: "size"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([ResizeObserverService, tuiButtonOptionsProvider({
        size: 'xs',
        appearance: 'flat'
      })]), i0.ɵɵHostDirectivesFeature([{
        directive: i1$1.TuiTextfieldOptionsDirective,
        inputs: ["tuiTextfieldSize", "size"]
      }]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 4,
      vars: 2,
      consts: [["rem", ""], [4, "ngFor", "ngForOf"], [1, "t-rem"], ["appearance", "secondary", "tuiIconButton", "", "tuiSearchFilter", "", "type", "button", "class", "t-button", 3, "_active", "size", 4, "ngIf"], [3, "ngTemplateOutlet", 4, "ngIf"], [3, "ngTemplateOutlet"], ["appearance", "secondary", "tuiIconButton", "", "tuiSearchFilter", "", "type", "button", 1, "t-button", 3, "size"], [1, "t-form", 3, "reset"], [1, "t-header", 3, "disabled"]],
      template: function TuiSearchFiltersComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, TuiSearchFiltersComponent_ng_container_0_Template, 2, 1, "ng-container", 1);
          i0.ɵɵelement(1, "div", 2, 0);
          i0.ɵɵtemplate(3, TuiSearchFiltersComponent_button_3_Template, 5, 5, "button", 3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", ctx.templates);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", ctx.overflown() < ctx.templates.length);
        }
      },
      dependencies: [NgForOf, NgIf, NgTemplateOutlet, TuiButton, TuiSearchFilterComponent],
      styles: ["[_nghost-%COMP%]{display:flex;flex:1;gap:inherit;justify-content:flex-end;min-inline-size:0}.t-rem[_ngcontent-%COMP%]{position:absolute;inline-size:1rem}.t-button[_ngcontent-%COMP%]{overflow:visible}.t-button._active[_ngcontent-%COMP%]:after{content:\"\";position:absolute;top:.25rem;right:.25rem;display:block;inline-size:.625rem;block-size:.625rem;background:var(--tui-status-warning);transform:translate(50%,-50%);border-radius:100%;margin:0}.t-form[_ngcontent-%COMP%]{display:flex;min-inline-size:25.5rem;max-inline-size:33rem;flex-direction:column;gap:.75rem;padding:0 1.5rem 1.5rem;box-sizing:border-box}.t-header[_ngcontent-%COMP%]{display:flex;justify-content:space-between;padding:1.25rem 0;margin:0 0 .5rem;border:none;box-shadow:inset 0 -1px var(--tui-border-normal);font:var(--tui-font-text-ui-l);font-weight:700}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSearchFiltersComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-search-filters',
      imports: [NgForOf, NgIf, NgTemplateOutlet, TuiButton, TuiSearchFilterComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [ResizeObserverService, tuiButtonOptionsProvider({
        size: 'xs',
        appearance: 'flat'
      })],
      hostDirectives: [{
        directive: TuiTextfieldOptionsDirective,
        inputs: ['tuiTextfieldSize: size']
      }],
      template: "<ng-container *ngFor=\"let template of templates; let i = index\">\n    <ng-container\n        *ngIf=\"i < overflown()\"\n        [ngTemplateOutlet]=\"template\"\n    />\n</ng-container>\n<div\n    #rem\n    class=\"t-rem\"\n></div>\n<button\n    *ngIf=\"overflown() < templates.length\"\n    appearance=\"secondary\"\n    tuiIconButton\n    tuiSearchFilter\n    type=\"button\"\n    class=\"t-button\"\n    [class._active]=\"enabled()\"\n    [size]=\"size\"\n>\n    <form\n        class=\"t-form\"\n        (reset)=\"onReset()\"\n    >\n        <fieldset\n            class=\"t-header\"\n            [disabled]=\"!enabled()\"\n        >\n            <ng-content />\n        </fieldset>\n        <ng-container *ngFor=\"let template of templates; let i = index\">\n            <ng-container\n                *ngIf=\"i >= overflown()\"\n                [ngTemplateOutlet]=\"template\"\n            />\n        </ng-container>\n    </form>\n</button>\n",
      styles: [":host{display:flex;flex:1;gap:inherit;justify-content:flex-end;min-inline-size:0}.t-rem{position:absolute;inline-size:1rem}.t-button{overflow:visible}.t-button._active:after{content:\"\";position:absolute;top:.25rem;right:.25rem;display:block;inline-size:.625rem;block-size:.625rem;background:var(--tui-status-warning);transform:translate(50%,-50%);border-radius:100%;margin:0}.t-form{display:flex;min-inline-size:25.5rem;max-inline-size:33rem;flex-direction:column;gap:.75rem;padding:0 1.5rem 1.5rem;box-sizing:border-box}.t-header{display:flex;justify-content:space-between;padding:1.25rem 0;margin:0 0 .5rem;border:none;box-shadow:inset 0 -1px var(--tui-border-normal);font:var(--tui-font-text-ui-l);font-weight:700}\n"]
    }]
  }], null, {
    rem: [{
      type: ViewChild,
      args: ['rem']
    }],
    button: [{
      type: ViewChild,
      args: [TuiButton, {
        read: ElementRef
      }]
    }],
    children: [{
      type: ContentChildren,
      args: [NgControl, {
        descendants: true
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [TuiItem, {
        descendants: true,
        read: TemplateRef
      }]
    }],
    size: [{
      type: Input
    }]
  });
})();
const TuiSearch = [TuiSearchComponent, TuiSearchFiltersComponent, TuiItem];

/**
 * Generated bundle index. Do not edit.
 */

export { TuiSearch, TuiSearchComponent, TuiSearchFiltersComponent };
