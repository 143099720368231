import { NgTemplateOutlet } from '@angular/common';
import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ContentService, ContentUrlPipe } from '@context/frontend/content';
import { getUaDevice } from '@context/frontend/native/platform';
import { Content } from '@context/shared/types/common';
import { TuiHint, TuiIcon, TuiLink, TuiLoader } from '@taiga-ui/core';

/**
 * Breadcrumbs are currently only needed for Folder Structure so this component
 * is going to be heavily integrated with the `ContentFolder` type.
 *
 * @todo eventually rethink how to make this more generic for any entity and breadcrumb instance
 */
@Component({
	standalone: true,
	imports: [
		TuiLink,
		TuiIcon,
		RouterLink,
		ContentUrlPipe,
		NgTemplateOutlet,
		TuiLoader,
		TuiHint,
	],
	selector: 'ctx-breadcrumbs',
	templateUrl: 'breadcrumbs.component.html',
	styleUrl: 'breadcrumbs.component.scss',
})
export class BreadcrumbsComponent implements OnInit {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	private _content!: Content;

	@Input()
	set content(value: Content) {
		this._content = value;
		this.createPath();
	}

	get content() {
		return this._content;
	}

	@Input()
	showHome = true;

	/**
	 * `true` if the breadcrumbs should show the content that was provided
	 * to generate the path.
	 *
	 * i.e. wanting to show the file being passed from the media details page.
	 */
	@Input()
	showContent = false;

	/**
	 * `true` if the breadcrumbs should not be interacted with and show
	 * in a disabled state. This was intended for archive detail views where the
	 * path of the content should not be interacted with, but needed to see for
	 * information
	 */
	@HostBinding('attr.disabled')
	@Input()
	disabled = false;

	readonly homePath = {
		name: 'Home',
		type: 'folder',
	} as Content;

	path: Content[] | null = [];

	@HostBinding('attr.path-overflow')
	overflow = false;

	loading = false;

	private readonly contentService = inject(ContentService);

	ngOnInit() {
		this.createPath();
	}

	createPath() {
		if (this.loading) return;
		this.loading = true;
		this.contentService
			.generateFolderPath(this.content)
			.then((res) => {
				this.path = res ?? [];
				this.overflow = (this.path?.length ?? 0) > 1;

				if (this.showContent) {
					this.path.push(this.content);
				}
			})
			.finally(() => {
				this.loading = false;
			});
	}
}
