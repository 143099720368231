import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RelativeTimePipe } from '@context/frontend/common';
import { AlgoliaMediaThumbnailComponent } from '@context/frontend/media';
import { AlgoliaMedia } from '@context/shared/types/search';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiIcon } from '@taiga-ui/core';
import { TuiBadge } from '@taiga-ui/kit';

@Component({
	standalone: true,
	imports: [
		AlgoliaMediaThumbnailComponent,
		RelativeTimePipe,
		TranslocoModule,
		TuiIcon,
		TuiBadge
	],
	selector: 'ctx-global-search-media',
	templateUrl: 'global-search-media.component.html',
	styleUrl: 'global-search-media.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchMediaComponent {
	@Input() media!: AlgoliaMedia;

	get updatedAt() {
		return new Date(this.media.updatedAt);
	}
}
