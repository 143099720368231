import { Component, Input } from '@angular/core';
import { AlgoliaAvatarComponent } from '@context/frontend/user';
import { AlgoliaUser } from '@context/shared/types/search';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiIcon } from '@taiga-ui/core';

@Component({
	standalone: true,
	imports: [AlgoliaAvatarComponent, TranslocoModule, TuiIcon],
	selector: 'ctx-global-search-user',
	templateUrl: 'global-search-user.component.html',
	styleUrl: 'global-search-user.component.scss',
})
export class GlobalSearchUserComponent {
	@Input() user!: AlgoliaUser;
}
