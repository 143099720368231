import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk/utils/miscellaneous';
const LAYOUT_ICONS = {
  filter: '@tui.filter',
  grid: '@tui.layout-grid'
};
const TUI_LAYOUT_ICONS = tuiCreateToken(LAYOUT_ICONS);
function tuiLayoutIconsProvider(icons) {
  return tuiProvideOptions(TUI_LAYOUT_ICONS, icons, LAYOUT_ICONS);
}
/**
 * @deprecated use {@link TUI_LAYOUT_ICONS} instead
 */
const TUI_COMMON_ICONS = TUI_LAYOUT_ICONS;
/**
 * @deprecated use {@link tuiLayoutIconsProvider} instead
 */
const tuiCommonIconsProvider = tuiLayoutIconsProvider;

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_COMMON_ICONS, TUI_LAYOUT_ICONS, tuiCommonIconsProvider, tuiLayoutIconsProvider };
