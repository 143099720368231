<tui-preview>
	<tui-preview-title>
		{{ context.data.content.name }}
	</tui-preview-title>

	<button
		iconStart="@tui.x"
		tuiIconButton
		tuiPreviewAction
		type="button"
		(click)="onDismiss()"
	>
		{{ 'action.dismiss' | transloco }}
	</button>

	@if (context.data.content.storagePath | getPathUrl | async; as src) {
		<img alt="preview" [src]="src" />
	} @else {
		<tui-loader
			class="media-preview__loader"
			[inheritColor]="true"
			size="xl"
		/>
	}
</tui-preview>
