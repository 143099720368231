import { inject, Injectable } from '@angular/core';
import {
	collection,
	collectionCount,
	collectionGroup,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	query,
	setDoc,
	startAfter,
	updateDoc,
} from '@angular/fire/firestore';
import {
	BaseActivityService,
	UpdateOptions,
} from '@context/frontend/api-client';
import { ContentService } from '@context/frontend/content';
import { Fetchable, PaginatedObject } from '@context/frontend/pagination';
import { StorageService } from '@context/frontend/storage';
import { AuthService } from '@context/frontend/user';
import { ActivityType } from '@context/shared/types/activity';
import { Content } from '@context/shared/types/common';
import {
	ContentMedia,
	createMedia,
	isImage,
} from '@context/shared/types/media';
import { User } from '@context/shared/types/user';
import { sanitize } from '@context/shared/utils';
import {
	CollectionReference,
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	Query,
	Timestamp,
	UpdateData,
} from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import { v4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class MediaService
	extends BaseActivityService<ContentMedia>
	implements Fetchable<ContentMedia>
{
	private static readonly CollectionId = 'media';

	readonly storageService = inject(StorageService);
	readonly contentService = inject(ContentService);
	readonly authService = inject(AuthService);

	async create(
		contentRef: DocumentReference<Content>,
		file: File,
		item: Partial<ContentMedia> = {},
	) {
		const now = Timestamp.now();
		const payload = createMedia(item, file) as ContentMedia;

		payload.createdAt = now;
		payload.updatedAt = now;
		payload.deletedAt = null;

		if (this.authService.userRef) {
			payload.createdBy = this.authService
				.userRef as DocumentReference<User>;
		}

		payload.id = v4();

		const path = await this.uploadFile(file, payload.id);
		payload.thumbnailPath = path.thumbnailPath;
		payload.storagePath = path.storagePath;

		const mediaCollection = collection(
			contentRef,
			MediaService.CollectionId,
		);

		const mediaRef = doc(
			mediaCollection,
			payload.id,
		) as DocumentReference<ContentMedia>;
		await setDoc(mediaRef, sanitize(payload));

		if (this.user.value?.id) {
			await this.logActivity({
				entity: mediaRef as DocumentReference<ContentMedia>,
				createdBy: this.user.value?.id,
				activity: { type: 'create' },
			});
		}

		return { data: payload, ref: mediaRef };
	}

	/**
	 * The mediaRef is required here so we can easily update the reference without needing to make
	 * a large id query against all of the collectionGroups.
	 */
	async update(
		payload: Partial<ContentMedia> & { file?: File },
		itemRef: DocumentReference<ContentMedia>,
		options: UpdateOptions,
	) {
		const file = payload.file;
		delete payload.file;
		if (file) {
			const path = await this.uploadFile(file, itemRef.id);
			payload.thumbnailPath = path.thumbnailPath;
			payload.storagePath = path.storagePath;
		}

		payload.updatedAt = Timestamp.now();
		const value = sanitize(payload);
		await updateDoc(itemRef, value as UpdateData<ContentMedia>);

		if (this.user.value?.id) {
			await this.logActivity({
				entity: itemRef,
				activity: { type: options.type as ActivityType },
				createdBy: this.user.value?.id,
			});
		}

		return value;
	}

	/**
	 * The mediaRef is required here so we ca easily "delete" the reference without needing to
	 * make a large id query against all of the collectionGroups
	 *
	 * @warning Only use `hard` delete for super admin level actions in the UI
	 */
	async delete(
		content: Content,
		mediaRef: DocumentReference<ContentMedia>,
		options?: { hard: boolean },
	) {
		// we need to ensure that the deleting media is not being used as the content thumbnail
		await this.contentService.checkAndRemoveContentMediaThumbnail({
			content,
			mediaRef,
		});

		const hard = options?.hard ?? false;
		if (hard) {
			const media = (await getDoc(mediaRef)).data() as ContentMedia;

			await Promise.all([
				this.storageService.deleteFromPath(media.storagePath),
				media.thumbnailPath
					? this.storageService.deleteFromPath(media.thumbnailPath)
					: () => Promise.resolve(),
			]);

			await deleteDoc(mediaRef);
		} else {
			const now = Timestamp.now();
			await updateDoc(
				mediaRef,
				sanitize({
					updatedAt: now,
					deletedAt: now,
				}) as UpdateData<ContentMedia>,
			);

			if (this.user.value?.id) {
				await this.logActivity({
					entity: mediaRef,
					activity: { type: 'delete' },
					createdBy: this.user.value?.id,
				});
			}
		}

		// empty object to ensure deletion success
		return {};
	}

	async archive(content: Content, mediaRef: DocumentReference<ContentMedia>) {
		// we need to ensure that the archiving media is not being used as the content thumbnail
		await this.contentService.checkAndRemoveContentMediaThumbnail({
			content,
			mediaRef,
		});

		return this.update({ archivedAt: Timestamp.now() }, mediaRef, {
			addActivityLog: true,
			type: 'archive',
		});
	}

	unarchive(mediaRef: DocumentReference<ContentMedia>) {
		return this.update({ archivedAt: null }, mediaRef, {
			addActivityLog: true,
			type: 'unarchive',
		});
	}

	async fetch(
		paginated?: PaginatedObject<ContentMedia>,
	): Promise<PaginatedObject<ContentMedia>> {
		if (!paginated) paginated = new PaginatedObject<ContentMedia>();

		let mediaQuery: Query<ContentMedia> | null = null;
		if (paginated.parentRef) {
			mediaQuery = query(
				collection(
					paginated.parentRef as DocumentReference<Content>,
					MediaService.CollectionId,
				) as CollectionReference<ContentMedia, DocumentData>,
			);
		} else if (this.organizationId.value) {
			mediaQuery = query(
				collectionGroup(
					this.firestore,
					MediaService.CollectionId,
				) as CollectionReference<ContentMedia>,
			);
		} else {
			throw new Error('Collection could not be determined');
		}

		if (!paginated.totalElements) {
			paginated.totalElements = await firstValueFrom(
				collectionCount(query(mediaQuery, ...paginated.constraints)),
			);
		}

		// if the object already contains the data, don't fetch again
		if (paginated.page) return paginated;

		const constraints = [...paginated.constraints];
		const lastDoc = paginated.previousPage?.last ?? null;
		if (lastDoc) constraints.push(startAfter(lastDoc));
		if (paginated.pageSize) constraints.push(limit(paginated.pageSize));
		const snapshot = await getDocs(query(mediaQuery, ...constraints));

		let last: DocumentSnapshot | null = null;
		const items = snapshot.docs.map((d) => {
			last = d;
			return { data: d.data(), ref: d.ref, id: d.id };
		});

		paginated.pages = Object.assign({}, paginated.pages, {
			[paginated.currentPage as number]: { last, items },
		});

		return paginated;
	}

	uploadFile(file: File, id: string) {
		return Promise.all([
			this.storageService
				.uploadFile(file, {
					id,
					entityType: 'media',
				})
				.then((ref) => ref.metadata.fullPath),
			isImage(file)
				? this.storageService
						.uploadFile(file, {
							id: `${id}_thumbnail`,
							compressPreset: 'thumbnail',
							entityType: 'media',
						})
						.then((ref) => ref.metadata.fullPath)
				: null,
		]).then(([storagePath, thumbnailPath]) => ({
			storagePath,
			thumbnailPath,
		}));
	}
}
