import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '@context/frontend/alert';
import {
	DestroyService,
	DialogBase,
	DialogOptions,
} from '@context/frontend/common';
import { applyValidators } from '@context/frontend/form';
import { StorageService } from '@context/frontend/storage';
import { AuthService } from '@context/frontend/user';
import {
	ContentMedia,
	ExistingFile,
	FileState,
	getExtension,
	removeExtension,
} from '@context/shared/types/media';
import { User, UserOption } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import {
	TuiButton,
	TuiError,
	TuiHint,
	TuiIcon,
	TuiTextfield,
} from '@taiga-ui/core';
import { TuiButtonLoading, TuiFieldErrorPipe, TuiFiles } from '@taiga-ui/kit';
import { DocumentReference } from 'firebase/firestore';
import { takeUntil } from 'rxjs';
import { MediaService } from '../../services';
import { Content } from '@context/shared/types/common';

@Component({
	standalone: true,
	imports: [
		TranslocoModule,
		TuiButton,
		TuiButtonLoading,
		TuiTextfield,
		TuiError,
		AsyncPipe,
		TuiFieldErrorPipe,
		TuiIcon,
		TuiFiles,
		TuiHint,
		ReactiveFormsModule,
	],
	selector: 'ctx-media-edit-dialog',
	templateUrl: 'media-edit-dialog.component.html',
	styleUrl: 'media-edit-dialog.component.scss',
	providers: [DestroyService],
})
export class MediaEditDialogComponent
	extends applyValidators(
		DialogBase<
			ContentMedia,
			{
				media: ContentMedia;
				mediaRef: DocumentReference<ContentMedia>;
				parent: Content;
			}
		>,
	)
	implements OnInit
{
	static override readonly DialogOptions = {
		size: 's',
	} as DialogOptions;

	readonly authService = inject(AuthService);
	readonly mediaService = inject(MediaService);
	readonly storageService = inject(StorageService);
	readonly alert = inject(AlertService);
	readonly destroy$ = inject(DestroyService);

	form: FormGroup | null = null;
	existingFile: ExistingFile | null = null;

	extension!: string;

	busy = false;

	ngOnInit() {
		this.setupForm();
	}

	async setupForm() {
		const mediaName = this.context.data.media.name;
		const name = removeExtension(mediaName);
		this.extension = getExtension(mediaName);

		this.form = new FormGroup({
			name: new FormControl([null], (field) =>
				this.getRequiredValidator(field, 'form.error.name.required'),
			),
			createdBy: new FormControl(null),
			file: new FormControl(null),
		});

		// fallback to authenticated user in case createdBy is not populated
		const createdBy = (this.context.data.media.createdBy ??
			this.authService.userRef) as DocumentReference<User>;
		const createdBySnapshot = await getDoc(createdBy);

		this.existingFile = await this.storageService
			.getBlob(
				this.storageService.getReference(
					this.context.data.media.storagePath,
				),
			)
			.then((blob) =>
				Object.assign(
					new File([blob], mediaName, { type: blob.type }),
					{
						state: 'active' as FileState,
						id: this.context.data.media.id,
					},
				),
			);

		this.form.patchValue({
			...this.context.data.media,
			name,
			createdBy: new UserOption(
				createdBySnapshot.data() as User,
				createdBySnapshot.ref,
			),
		});

		this.form.get('createdBy')?.disable();

		this.form
			.get('file')
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				if (value) this.onExistingFileRemove();
			});
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;

		// check to see if the image state is correct
		const value = this.form.getRawValue();
		if (this.existingFile?.state === 'active') {
			delete value.file;
		} else if (!value.file) {
			this.form.get('file')?.setErrors({ required: true });
			return;
		}

		this.busy = true;
		this.form.disable();

		const createdByValue = value.createdBy as UserOption;
		value.createdBy = createdByValue.valueOf();

		// reapply the extension split
		value.name = `${value.name.trim()}${this.extension}`;

		return this.mediaService
			.update(value, this.context.data.mediaRef, {
				addActivityLog: true,
				type: 'update',
			})
			.then((media) => {
				this.alert.open('generic-success').subscribe();
				this.onDismiss(media as ContentMedia);
			})
			.catch((error) => {
				this.alert.open('generic-error').subscribe();
				console.error(error);
				throw new Error('There was an issue updating the media item');
			})
			.finally(() => {
				this.busy = false;
				this.form?.enable();
				this.form?.get('createdBy')?.disable();
			});
	}

	onFileRemove() {
		this.form?.get('file')?.setValue(null);
	}

	onExistingFileRemove() {
		if (!this.existingFile) return;
		Object.assign(this.existingFile, { state: 'deleted' });
	}

	onRemovedFileRestore() {
		if (!this.existingFile) return;
		this.onFileRemove();
		Object.assign(this.existingFile, { state: 'active' });
	}
}
