<ng-content select="header" />

@if (mode === 'detail') {
	<div class="page-template__scroll-container">
		<ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
	</div>
} @else if (mode === 'table') {
	<ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
}

<ng-template #contentTemplate>
	<ng-content />
</ng-template>
