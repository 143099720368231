import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TablePlaceholderComponent } from '@context/frontend/table';
import { AlgoliaGlobal } from '@context/shared/types/search';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiLoader } from '@taiga-ui/core';
import { GlobalSearchFileComponent } from '../../global-search-file/global-search-file.component';
import { GlobalSearchFolderComponent } from '../../global-search-folder/global-search-folder.component';
import { GlobalSearchMediaComponent } from '../../global-search-media/global-search-media.component';
import { GlobalSearchUserComponent } from '../../global-search-user/global-search-user.component';
import { USER } from '@context/frontend/common';
import { RoleAccessPipe } from '@context/frontend/user';
import { BehaviorSubject } from 'rxjs';

@Component({
	standalone: true,
	imports: [
		TuiLoader,
		TranslocoModule,
		GlobalSearchUserComponent,
		GlobalSearchMediaComponent,
		GlobalSearchFolderComponent,
		GlobalSearchFileComponent,
		TablePlaceholderComponent,
		RoleAccessPipe,
	],
	selector: 'ctx-global-search-dropdown',
	templateUrl: 'global-search-dropdown.component.html',
	styleUrl: 'global-search-dropdown.component.scss',
})
export class GlobalSearchDropdownComponent {
	@Input()
	loading = false;

	@Input()
	data: AlgoliaGlobal<any>[] = [];

	@Input()
	totalElements = 0;

	@Output()
	select = new EventEmitter<void>();

	readonly router = inject(Router);
	readonly user = inject(USER);

	onSelect(path: string[]) {
		this.router.navigate(path);
		this.select.emit();
	}
}
