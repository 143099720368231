<ctx-algolia-media-thumbnail [media]="media" />

<div class="global-search-media__information">
	<p class="margin-0">
		<tui-icon icon="@tui.file" />
		{{ media.name }}
	</p>

	<small class="margin-0">
		{{
			'data.last-edited-at'
				| transloco: { time: updatedAt | relativeTime }
		}}
	</small>
</div>

<div class="global-search-media__actions">
	<tui-badge appearance="info">
		<tui-icon icon="@tui.sprout" />

		<span class="global-search-media__actions-badge-text">
			{{ media.metadata.parentName }}
		</span>
	</tui-badge>
</div>
