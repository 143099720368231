import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { tuiPop } from '@taiga-ui/core/animations';
import { TUI_ANIMATIONS_SPEED } from '@taiga-ui/core/tokens';
import { tuiToAnimationOptions } from '@taiga-ui/core/utils/miscellaneous';
import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk/utils/miscellaneous';
const _c0 = ["*"];
const TUI_BADGE_NOTIFICATION_DEFAULT_OPTIONS = {
  size: 'm'
};
const TUI_BADGE_NOTIFICATION_OPTIONS = tuiCreateToken(TUI_BADGE_NOTIFICATION_DEFAULT_OPTIONS);
function tuiBadgeNotificationOptionsProvider(options) {
  return tuiProvideOptions(TUI_BADGE_NOTIFICATION_OPTIONS, options, TUI_BADGE_NOTIFICATION_DEFAULT_OPTIONS);
}
class TuiBadgeNotification {
  constructor() {
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.size = inject(TUI_BADGE_NOTIFICATION_OPTIONS).size;
  }
  static {
    this.ɵfac = function TuiBadgeNotification_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiBadgeNotification)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiBadgeNotification,
      selectors: [["tui-badge-notification"]],
      hostVars: 2,
      hostBindings: function TuiBadgeNotification_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵsyntheticHostProperty("@tuiPop", ctx.options);
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      inputs: {
        size: "size"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TuiBadgeNotification_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{--t-size: 1.5rem;position:relative;display:inline-flex;align-items:center;justify-content:center;box-sizing:border-box;color:#fff;border-radius:2rem;white-space:nowrap;overflow:hidden;vertical-align:middle;font:var(--tui-font-text-s);max-inline-size:100%;padding:0 .25rem;background:#f52222;block-size:var(--t-size);min-inline-size:var(--t-size)}[data-size=m][_nghost-%COMP%]{--t-size: 1.25rem}[data-size=s][_nghost-%COMP%]{--t-size: 1rem;padding:0 .125rem;font:var(--tui-font-text-xs)}[data-size=xs][_nghost-%COMP%]{--t-size: .375rem;padding:0;font-size:0}[_nghost-%COMP%]   [tuiIconButton][_nghost-%COMP%], [tuiIconButton]   [_nghost-%COMP%]{position:absolute;right:25%;top:25%}"],
      data: {
        animation: [tuiPop]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiBadgeNotification, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-badge-notification',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiPop],
      host: {
        '[attr.data-size]': 'size',
        '[@tuiPop]': 'options'
      },
      styles: [":host{--t-size: 1.5rem;position:relative;display:inline-flex;align-items:center;justify-content:center;box-sizing:border-box;color:#fff;border-radius:2rem;white-space:nowrap;overflow:hidden;vertical-align:middle;font:var(--tui-font-text-s);max-inline-size:100%;padding:0 .25rem;background:#f52222;block-size:var(--t-size);min-inline-size:var(--t-size)}:host[data-size=m]{--t-size: 1.25rem}:host[data-size=s]{--t-size: 1rem;padding:0 .125rem;font:var(--tui-font-text-xs)}:host[data-size=xs]{--t-size: .375rem;padding:0;font-size:0}:host :host-context([tuiIconButton]){position:absolute;right:25%;top:25%}\n"]
    }]
  }], null, {
    size: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_BADGE_NOTIFICATION_DEFAULT_OPTIONS, TUI_BADGE_NOTIFICATION_OPTIONS, TuiBadgeNotification, tuiBadgeNotificationOptionsProvider };
