<tui-preview class="doc-viewer" [rotatable]="false" [zoomable]="false">
	<tui-preview-title>
		{{ context.data.label }}
	</tui-preview-title>

	<button
		iconStart="@tui.x"
		tuiIconButton
		tuiPreviewAction
		type="button"
		(click)="onDismiss()"
	>
		{{ 'action.dismiss' | transloco }}
	</button>

	<tui-loader
		class="document-preview__loader"
		[inheritColor]="true"
		size="xl"
	/>

	<iframe
		class="document-preview__frame"
		[src]="context.data.safeUrl"
		frameborder="0"
	></iframe>
</tui-preview>
