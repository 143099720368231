<ctx-media-thumbnail
	class="media-row__thumbnail"
	[media]="media"
	[badgeIcon]="media.log ? '@tui.notebook-pen' : null"
	[badgeHint]="media.log ? ('media.attached-to-log' | transloco) : null"
></ctx-media-thumbnail>

<div class="media-row__information">
	<span class="media-row__information-header">
		<p class="margin-0">
			{{ media.name }}
		</p>

		@if (media.setAsThumbnail) {
			<tui-icon
				[style.color]="'var(--context-color-primary)'"
				[tuiHint]="'media.set-as-thumbnail' | transloco"
				tuiHintAppearance="dark"
				[style.width.px]="20"
				[style.height.px]="20"
				icon="@tui.image"
			/>
		}
	</span>

	@if (isArchived) {
		<tui-badge class="margin-top-1" appearance="neutral">
			{{ 'content.archived' | transloco }}
		</tui-badge>
	} @else {
		<small class="margin-0">
			{{
				'data.last-edited-at'
					| transloco: { time: media.updatedAt | relativeTime }
			}}
		</small>
	}
</div>

<div class="media-row__actions">
	@if (parent && showParent) {
		<tui-badge appearance="info">
			@switch (parent.type) {
				@case ('file') {
					<tui-icon icon="@tui.sprout" />
				}
				@case ('folder') {
					<tui-icon icon="@tui.folder" />
				}
			}

			<span class="media-row__actions-badge-text">
				{{ parent.name }}
			</span>
		</tui-badge>
	}

	@if (!isArchived) {
		<button tuiIconButton appearance="ghost" size="m" (click)="onView()">
			<tui-icon icon="@tui.eye"></tui-icon>
		</button>
	}

	@if (menuItems.length) {
		@if ('mobile' | isDevice) {
			<button
				size="m"
				tuiIconButton
				appearance="ghost"
				(click)="onMenuOpen()"
			>
				<tui-icon icon="@tui.ellipsis"></tui-icon>
			</button>
		} @else {
			<button
				size="m"
				tuiIconButton
				appearance="ghost"
				[tuiDropdown]="fileDropdown"
				[(tuiDropdownOpen)]="menuOpen"
			>
				<tui-icon icon="@tui.ellipsis"></tui-icon>
			</button>
		}
	}
</div>

<ng-template #fileDropdown="polymorpheus" polymorpheus>
	<ctx-detail-menu-dropdown
		[menuItems]="menuItems"
	></ctx-detail-menu-dropdown>
</ng-template>
