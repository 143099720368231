import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Input, EventEmitter, ElementRef, ContentChildren, forwardRef, Output, ChangeDetectorRef, Directive } from '@angular/core';
import { EMPTY_QUERY } from '@taiga-ui/cdk/constants';
import { TuiRepeatTimes } from '@taiga-ui/cdk/directives/repeat-times';
import { tuiIsElement, tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { NgIf } from '@angular/common';
import { tuiScaleIn } from '@taiga-ui/core/animations';
import { TuiIcon } from '@taiga-ui/core/components/icon';
import { TUI_ANIMATIONS_SPEED } from '@taiga-ui/core/tokens';
import { tuiToAnimationOptions } from '@taiga-ui/core/utils/miscellaneous';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLinkActive } from '@angular/router';
import { tuiWatch } from '@taiga-ui/cdk/observables';
import { EMPTY, filter } from 'rxjs';
const _c0 = ["tuiTabBarItem", ""];
const _c1 = ["*"];
function TuiTabBarItem_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const value_r1 = ctx.ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@tuiScaleIn", ctx_r1.options);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.format(value_r1), " ");
  }
}
const _c2 = ["tuiTabBar", ""];
function TuiTabBarComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 2);
  }
}
class TuiTabBarItem {
  constructor() {
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.icon = '';
    this.badge = null;
  }
  format(value) {
    return value > 999 ? '999+' : String(value);
  }
  static {
    this.ɵfac = function TuiTabBarItem_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTabBarItem)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiTabBarItem,
      selectors: [["button", "tuiTabBarItem", ""], ["a", "tuiTabBarItem", ""]],
      inputs: {
        icon: "icon",
        badge: "badge"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 5,
      vars: 2,
      consts: [[1, "t-icon", 3, "icon"], [1, "t-wrapper"], ["class", "t-badge", 4, "ngIf"], [1, "t-text"], [1, "t-badge"]],
      template: function TuiTabBarItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelement(0, "tui-icon", 0);
          i0.ɵɵelementStart(1, "span", 1);
          i0.ɵɵtemplate(2, TuiTabBarItem_span_2_Template, 2, 2, "span", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "span", 3);
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("icon", ctx.icon);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.badge);
        }
      },
      dependencies: [NgIf, TuiIcon],
      styles: ["[_nghost-%COMP%]{-webkit-appearance:none;appearance:none;padding:0;border:0;background:none;font-size:inherit;line-height:inherit;text-decoration:none;transition-property:color;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;position:relative;display:flex;flex:1 0;font:inherit;flex-direction:column;align-items:center;overflow:hidden}[_nghost-%COMP%]:nth-child(1){color:var(--tui-tab-1, inherit)}[_nghost-%COMP%]:nth-child(2){color:var(--tui-tab-2, inherit)}[_nghost-%COMP%]:nth-child(3){color:var(--tui-tab-3, inherit)}[_nghost-%COMP%]:nth-child(4){color:var(--tui-tab-4, inherit)}[_nghost-%COMP%]:nth-child(5){color:var(--tui-tab-5, inherit)}[_nghost-%COMP%]:nth-child(6){color:var(--tui-tab-6, inherit)}[_nghost-%COMP%]:nth-child(7){color:var(--tui-tab-7, inherit)}[_nghost-%COMP%]:nth-child(8){color:var(--tui-tab-8, inherit)}[_nghost-%COMP%]:nth-child(9){color:var(--tui-tab-9, inherit)}[_nghost-%COMP%]:nth-child(10){color:var(--tui-tab-10, inherit)}.t-icon[_ngcontent-%COMP%]{inline-size:1.75rem;block-size:1.75rem;margin:.375rem 0 .125rem;pointer-events:none;border:.125rem solid transparent}.t-wrapper[_ngcontent-%COMP%]{position:absolute;top:.125rem;left:1rem;display:flex;inline-size:100%;justify-content:center;pointer-events:none}.t-badge[_ngcontent-%COMP%]{display:flex;block-size:1.125rem;min-inline-size:1.125rem;align-items:center;justify-content:center;padding:0 .25rem;border-radius:1rem;font-size:.8125rem;box-sizing:border-box;color:var(--tui-background-base);background:var(--tui-status-negative)}.t-text[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-inline-size:100%;pointer-events:none}"],
      data: {
        animation: [tuiScaleIn]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTabBarItem, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'button[tuiTabBarItem], a[tuiTabBarItem]',
      imports: [NgIf, TuiIcon],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiScaleIn],
      template: "<tui-icon\n    class=\"t-icon\"\n    [icon]=\"icon\"\n/>\n<span class=\"t-wrapper\">\n    <span\n        *ngIf=\"badge as value\"\n        class=\"t-badge\"\n        [@tuiScaleIn]=\"options\"\n    >\n        {{ format(value) }}\n    </span>\n</span>\n<span class=\"t-text\">\n    <ng-content />\n</span>\n",
      styles: [":host{-webkit-appearance:none;appearance:none;padding:0;border:0;background:none;font-size:inherit;line-height:inherit;text-decoration:none;transition-property:color;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;position:relative;display:flex;flex:1 0;font:inherit;flex-direction:column;align-items:center;overflow:hidden}:host:nth-child(1){color:var(--tui-tab-1, inherit)}:host:nth-child(2){color:var(--tui-tab-2, inherit)}:host:nth-child(3){color:var(--tui-tab-3, inherit)}:host:nth-child(4){color:var(--tui-tab-4, inherit)}:host:nth-child(5){color:var(--tui-tab-5, inherit)}:host:nth-child(6){color:var(--tui-tab-6, inherit)}:host:nth-child(7){color:var(--tui-tab-7, inherit)}:host:nth-child(8){color:var(--tui-tab-8, inherit)}:host:nth-child(9){color:var(--tui-tab-9, inherit)}:host:nth-child(10){color:var(--tui-tab-10, inherit)}.t-icon{inline-size:1.75rem;block-size:1.75rem;margin:.375rem 0 .125rem;pointer-events:none;border:.125rem solid transparent}.t-wrapper{position:absolute;top:.125rem;left:1rem;display:flex;inline-size:100%;justify-content:center;pointer-events:none}.t-badge{display:flex;block-size:1.125rem;min-inline-size:1.125rem;align-items:center;justify-content:center;padding:0 .25rem;border-radius:1rem;font-size:.8125rem;box-sizing:border-box;color:var(--tui-background-base);background:var(--tui-status-negative)}.t-text{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-inline-size:100%;pointer-events:none}\n"]
    }]
  }], null, {
    icon: [{
      type: Input
    }],
    badge: [{
      type: Input
    }]
  });
})();
class TuiTabBarComponent {
  constructor() {
    this.tabs = EMPTY_QUERY;
    this.quantity = 4;
    this.activeItemIndex = NaN;
    this.activeItemIndexChange = new EventEmitter();
  }
  setActive(tab) {
    if (tuiIsElement(tab)) {
      this.updateIndex(this.tabs.toArray().findIndex(({
        nativeElement
      }) => nativeElement === tab));
    }
  }
  get style() {
    return `--tui-tab-${this.activeItemIndex + 1}: var(--tui-active-color)`;
  }
  updateIndex(index) {
    this.activeItemIndex = index;
    this.activeItemIndexChange.emit(index);
  }
  static {
    this.ɵfac = function TuiTabBarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTabBarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiTabBarComponent,
      selectors: [["nav", "tuiTabBar", ""]],
      contentQueries: function TuiTabBarComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, TuiTabBarItem, 4, ElementRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabs = _t);
        }
      },
      hostVars: 2,
      hostBindings: function TuiTabBarComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function TuiTabBarComponent_click_HostBindingHandler($event) {
            return ctx.setActive($event.target);
          });
        }
        if (rf & 2) {
          i0.ɵɵstyleMap(ctx.style);
        }
      },
      inputs: {
        quantity: "quantity",
        activeItemIndex: "activeItemIndex"
      },
      outputs: {
        activeItemIndexChange: "activeItemIndexChange"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      attrs: _c2,
      ngContentSelectors: _c1,
      decls: 3,
      vars: 1,
      consts: [[1, "t-skeletons"], ["class", "t-skeleton", 4, "tuiRepeatTimes", "tuiRepeatTimesOf"], [1, "t-skeleton"]],
      template: function TuiTabBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "div", 0);
          i0.ɵɵtemplate(2, TuiTabBarComponent_div_2_Template, 1, 0, "div", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("tuiRepeatTimesOf", ctx.quantity);
        }
      },
      dependencies: [TuiRepeatTimes],
      styles: ["[_nghost-%COMP%]{--tui-active-color: var(--tui-text-action);position:relative;display:flex;font: .625rem/.75rem -apple-system,BlinkMacSystemFont,system-ui,Roboto,Segoe UI,sans-serif;block-size:var(--tui-height-l);color:var(--tui-text-secondary);-webkit-backdrop-filter:blur(.3125rem);backdrop-filter:blur(.3125rem)}[_nghost-%COMP%]:before{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;content:\"\";background:var(--tui-background-base-alt);box-shadow:inset 0 1px var(--tui-border-normal);opacity:.8}[tuiTheme=dark]   [_nghost-%COMP%]:before{opacity:.7}.t-skeletons[_ngcontent-%COMP%]{display:none;block-size:100%;inline-size:100%;justify-content:space-around}.t-skeletons[_ngcontent-%COMP%]:first-child{display:flex}.t-skeleton[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:center;justify-content:center;animation:tuiSkeletonVibe ease-in-out 1s infinite alternate}.t-skeleton[_ngcontent-%COMP%]:before{content:\"\";inline-size:1.375rem;block-size:1.375rem;margin-bottom:.5rem;border-radius:100%;background:var(--tui-background-neutral-2)}.t-skeleton[_ngcontent-%COMP%]:after{content:\"\";inline-size:2.5rem;block-size:.375rem;border-radius:1rem;background:var(--tui-background-neutral-2)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTabBarComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'nav[tuiTabBar]',
      imports: [TuiRepeatTimes],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[style]': 'style',
        '(click)': 'setActive($event.target)'
      },
      template: "<ng-content />\n<div class=\"t-skeletons\">\n    <div\n        *tuiRepeatTimes=\"let index of quantity\"\n        class=\"t-skeleton\"\n    ></div>\n</div>\n",
      styles: [":host{--tui-active-color: var(--tui-text-action);position:relative;display:flex;font: .625rem/.75rem -apple-system,BlinkMacSystemFont,system-ui,Roboto,Segoe UI,sans-serif;block-size:var(--tui-height-l);color:var(--tui-text-secondary);-webkit-backdrop-filter:blur(.3125rem);backdrop-filter:blur(.3125rem)}:host:before{position:absolute;top:0;left:0;inline-size:100%;block-size:100%;content:\"\";background:var(--tui-background-base-alt);box-shadow:inset 0 1px var(--tui-border-normal);opacity:.8}[tuiTheme=dark] :host:before{opacity:.7}.t-skeletons{display:none;block-size:100%;inline-size:100%;justify-content:space-around}.t-skeletons:first-child{display:flex}.t-skeleton{display:flex;flex-direction:column;align-items:center;justify-content:center;animation:tuiSkeletonVibe ease-in-out 1s infinite alternate}.t-skeleton:before{content:\"\";inline-size:1.375rem;block-size:1.375rem;margin-bottom:.5rem;border-radius:100%;background:var(--tui-background-neutral-2)}.t-skeleton:after{content:\"\";inline-size:2.5rem;block-size:.375rem;border-radius:1rem;background:var(--tui-background-neutral-2)}\n"]
    }]
  }], null, {
    tabs: [{
      type: ContentChildren,
      args: [forwardRef(() => TuiTabBarItem), {
        read: ElementRef
      }]
    }],
    quantity: [{
      type: Input
    }],
    activeItemIndex: [{
      type: Input
    }],
    activeItemIndexChange: [{
      type: Output
    }]
  });
})();
class TuiTabBarItemDirective {
  constructor() {
    const tabs = inject(TuiTabBarComponent);
    const el = tuiInjectElement();
    const link = inject(RouterLinkActive, {
      optional: true
    })?.isActiveChange || EMPTY;
    link.pipe(filter(Boolean), tuiWatch(inject(ChangeDetectorRef)), takeUntilDestroyed()).subscribe(() => tabs.setActive(el));
  }
  static {
    this.ɵfac = function TuiTabBarItemDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTabBarItemDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTabBarItemDirective,
      selectors: [["", "tuiTabBarItem", "", "routerLinkActive", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTabBarItemDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiTabBarItem][routerLinkActive]'
    }]
  }], function () {
    return [];
  }, null);
})();
const TuiTabBar = [TuiTabBarComponent, TuiTabBarItem, TuiTabBarItemDirective];

/**
 * Generated bundle index. Do not edit.
 */

export { TuiTabBar, TuiTabBarComponent, TuiTabBarItem, TuiTabBarItemDirective };
