<search tuiSearch>
	<form [formGroup]="search">
		<fieldset tuiTextfieldSize="m">
			<tui-textfield iconStart="@tui.search">
				<input
					autofocus
					formControlName="term"
					[placeholder]="'data.global-search-placeholder' | transloco"
					tuiTextfield
				/>
			</tui-textfield>
		</fieldset>
	</form>
</search>

@if (loading) {
	<div class="padding-v-4">
		<tui-loader size="l" />
	</div>
} @else if (data.length) {
	<div class="padding-v-4">
		@for (item of data; track item.objectID) {
			@switch (item.type) {
				@case ('user') {
					@if (['/users/', item.objectID, 'content']; as path) {
						<ctx-global-search-user
							[user]="item"
							tabindex="0"
							(click)="onSelect(path)"
							(keyup.enter)="onSelect(path)"
						/>
					}
				}
				@case ('file') {
					@if (['/home/file/', item.objectID]; as path) {
						<ctx-global-search-file
							[file]="item"
							tabindex="0"
							(click)="onSelect(path)"
							(keyup.enter)="onSelect(path)"
						/>
					}
				}
				@case ('folder') {
					@if (['/home/folder/', item.objectID]; as path) {
						<ctx-global-search-folder
							[folder]="item"
							tabindex="0"
							(click)="onSelect(path)"
							(keyup.enter)="onSelect(path)"
						/>
					}
				}
				@case ('media') {
					@if (
						['/home/file/', item.metadata.parentId, 'media'];
						as path
					) {
						<ctx-global-search-media
							[media]="item"
							tabindex="0"
							(click)="onSelect(path)"
							(keyup.enter)="onSelect(path)"
						/>
					}
				}
			}
		}
	</div>
} @else if (search.get('term')?.value) {
	<ctx-table-placeholder
		[header]="'data.search-placeholder-header' | transloco"
		[content]="'data.search-placeholder-content' | transloco"
		icon="@tui.search"
	></ctx-table-placeholder>
}
