import * as i0 from '@angular/core';
import { inject, Injectable, Directive, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { tuiTypedFromEvent } from '@taiga-ui/cdk/observables';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { tuiIsPresent } from '@taiga-ui/cdk/utils/miscellaneous';
import { Observable, merge, pairwise, filter, map } from 'rxjs';
import { tuiCreateToken } from '@taiga-ui/cdk/utils';
const TUI_SWIPE_OPTIONS = tuiCreateToken({
  timeout: 500,
  threshold: 30
});
class TuiSwipeService extends Observable {
  constructor() {
    const doc = inject(DOCUMENT);
    const el = tuiInjectElement();
    const {
      timeout,
      threshold
    } = inject(TUI_SWIPE_OPTIONS);
    super(subscriber => merge(tuiTypedFromEvent(el, 'touchstart', {
      passive: true
    }), tuiTypedFromEvent(doc, 'touchend')).pipe(pairwise(), filter(([first, second]) => !!first.touches.length && first.touches[0]?.identifier === second.changedTouches[0]?.identifier), map(([start, end]) => {
      const startX = start.touches[0]?.clientX ?? 0;
      const startY = start.touches[0]?.clientY ?? 0;
      const endX = end.changedTouches[0]?.clientX ?? 0;
      const endY = end.changedTouches[0]?.clientY ?? 0;
      const distanceX = startX - endX;
      const distanceY = startY - endY;
      const duration = end.timeStamp - start.timeStamp;
      if ((Math.abs(distanceX) > threshold || Math.abs(distanceY) > threshold) && duration < timeout) {
        return {
          direction: tuiGetSwipeDirection(distanceX, distanceY),
          events: [start, end]
        };
      }
      return null;
    }), filter(tuiIsPresent)).subscribe(subscriber));
  }
  static {
    this.ɵfac = function TuiSwipeService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSwipeService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiSwipeService,
      factory: TuiSwipeService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSwipeService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
function tuiGetSwipeDirection(deltaX, deltaY) {
  if (Math.abs(deltaY) > Math.abs(deltaX)) {
    return deltaY > 0 ? 'top' : 'bottom';
  }
  return deltaX > 0 ? 'left' : 'right';
}
class TuiSwipe {
  constructor() {
    this.tuiSwipe = inject(TuiSwipeService);
  }
  static {
    this.ɵfac = function TuiSwipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSwipe)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiSwipe,
      selectors: [["", "tuiSwipe", ""]],
      outputs: {
        tuiSwipe: "tuiSwipe"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiSwipeService])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSwipe, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiSwipe]',
      providers: [TuiSwipeService]
    }]
  }], null, {
    tuiSwipe: [{
      type: Output
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_SWIPE_OPTIONS, TuiSwipe, TuiSwipeService };
