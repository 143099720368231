<section tuiContent>
	<form [formGroup]="form" (ngSubmit)="onUpload()">
		<label class="margin-top-6" tuiInputFiles>
			<input
				#validator="tuiInputFilesValidator"
				tuiInputFiles
				[accept]="acceptedFiles"
				formControlName="files"
				[multiple]="true"
			/>
		</label>

		<tui-files class="margin-top-1" [max]="3">
			@for (file of form.get('files')?.valueChanges | async; track file) {
				@if (fileStateMap.get(file); as state) {
					<!-- Normal indicates the file was uploaded successfully -->
					<tui-file
						*tuiItem
						[state]="state"
						[showDelete]="state === 'error'"
						[file]="
							(file | tuiFileRejected: validator | async) || file
						"
						(remove)="onRemove(file)"
					>
						<small>{{ 'media.uploading' | transloco }}</small>
					</tui-file>
				} @else {
					<!-- If the file hasn't been designated a state, it's idling -->
					<tui-file
						*tuiItem
						[leftContent]="idle"
						[file]="
							(file | tuiFileRejected: validator | async) || file
						"
						(remove)="onRemove(file)"
					>
						<small>
							{{ 'media.waiting-for-upload' | transloco }}
						</small>
					</tui-file>
				}
			}
		</tui-files>

		<tui-error
			[error]="['maxLength'] | tuiFieldError | async"
			formControlName="files"
		/>
	</form>
</section>

<footer tuiFooter>
	<button
		tuiButton
		(click)="onDismiss()"
		appearance="outline"
		[disabled]="busy"
	>
		{{ 'action.cancel' | transloco }}
	</button>
	<button
		tuiButton
		(click)="onUpload()"
		[disabled]="form.invalid"
		[loading]="busy"
		appearance="accent"
	>
		{{ 'action.upload' | transloco }}
	</button>
</footer>

<ng-template #idle>
	<tui-icon icon="@tui.clock" />
</ng-template>
