import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import {
	AuthService,
	AvatarComponent,
	RoleAccessPipe,
	UserNamePipe,
} from '@context/frontend/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiLink } from '@taiga-ui/core';
import { MenuItem } from '../../components';

@Component({
	standalone: true,
	imports: [
		TuiIcon,
		TuiButton,
		TranslocoModule,
		AvatarComponent,
		UserNamePipe,
		TuiLink,
		RouterLink,
		RouterLinkActive,
		RoleAccessPipe,
	],
	selector: 'ctx-mobile-menu-dialog',
	templateUrl: 'mobile-menu-dialog.component.html',
	styleUrl: 'mobile-menu-dialog.component.scss',
})
export class MobileMenuDialogComponent extends DialogBase<
	MenuItem,
	{ menuItems: MenuItem[] }
> {
	static override readonly DialogOptions = {
		size: 'fullscreen',
		closeable: false,
	} as DialogOptions;

	public readonly authService = inject(AuthService);

	onClick(item: MenuItem) {
		if (item.handler) item.handler();
		this.context.completeWith(item);
	}
}
