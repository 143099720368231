import {
	Component,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ViewActivityDialogComponent } from '@context/frontend/activity';
import { AlertService } from '@context/frontend/alert';
import { DialogService, RelativeTimePipe } from '@context/frontend/common';
import { ContentService } from '@context/frontend/content';
import { getUaDevice, IsDevicePipe } from '@context/frontend/native/platform';
import { StorageService } from '@context/frontend/storage';
import {
	AcknowledgeDialogComponent,
	DetailMenuDialogComponent,
	DetailMenuDropdownComponent,
} from '@context/frontend/ui/components';
import { AuthService } from '@context/frontend/user';
import { ViewerService } from '@context/frontend/viewer';
import {
	Content,
	DetailMenuItem,
	ViewAccess,
} from '@context/shared/types/common';
import {
	ContentMedia,
	downloadBlob,
	isMediaImage,
} from '@context/shared/types/media';
import { hasInheritedRolePermission, User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiDropdown, TuiHint, TuiIcon } from '@taiga-ui/core';
import { TuiBadge } from '@taiga-ui/kit';
import {
	PolymorpheusComponent,
	PolymorpheusOutlet,
	PolymorpheusTemplate,
} from '@taiga-ui/polymorpheus';
import { DocumentReference } from 'firebase/firestore';
import { MediaEditDialogComponent } from '../../dialogs';
import { MediaService } from '../../services';
import { MediaThumbnailComponent } from '../media-thumbnail/media-thumbnail.component';

@Component({
	standalone: true,
	imports: [
		TranslocoModule,
		RelativeTimePipe,
		MediaThumbnailComponent,
		TuiButton,
		TuiIcon,
		DetailMenuDropdownComponent,
		TuiDropdown,
		IsDevicePipe,
		PolymorpheusOutlet,
		PolymorpheusTemplate,
		TuiHint,
		TuiBadge,
	],
	selector: 'ctx-media-row',
	templateUrl: 'media-row.component.html',
	styleUrl: 'media-row.component.scss',
})
export class MediaRowComponent implements OnInit {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@HostBinding('attr.archived')
	isArchived = false;

	@Input()
	media!: ContentMedia;

	@Input()
	mediaRef!: DocumentReference<ContentMedia>;

	@Input()
	parent!: Content;

	@Input()
	showParent = false;

	@Input()
	access: ViewAccess = 'edit';

	@Output()
	update = new EventEmitter<void>();

	@Output()
	preview = new EventEmitter<void>();

	private readonly dialog = inject(DialogService);
	private readonly storageService = inject(StorageService);
	private readonly contentService = inject(ContentService);
	private readonly mediaService = inject(MediaService);
	private readonly alert = inject(AlertService);
	private readonly authService = inject(AuthService);
	private readonly viewer = inject(ViewerService);
	private readonly router = inject(Router);

	menuOpen = false;

	menuItems: DetailMenuItem[] = [];

	readonly defaultMenuItems: DetailMenuItem[] = [
		{
			text: 'action.download',
			iconStart: '@tui.download',
			handler: () => this.onDownload(),
		},
		{
			text: 'action.view-history',
			iconStart: '@tui.history',
			handler: () => this.onViewHistory(),
		},
	];

	readonly activeMenuItems: DetailMenuItem[] = [
		{
			text: 'content.edit-details',
			iconStart: '@tui.pen-line',
			handler: () => this.onEdit(),
		},
		{
			text: 'content.action.archive',
			iconStart: '@tui.archive',
			handler: () => this.onArchive(),
		},
	];

	readonly mediaDetailMenu = () =>
		this.dialog.open(
			new PolymorpheusComponent(DetailMenuDialogComponent),
			DetailMenuDialogComponent.Options({
				label: 'media.manage-media-item',
				data: { menuItems: this.menuItems },
			}),
		);

	readonly mediaActivityDialog = () =>
		this.dialog.open(
			new PolymorpheusComponent(ViewActivityDialogComponent),
			ViewActivityDialogComponent.Options({
				data: { entityRef: this.mediaRef },
			}),
		);

	readonly acknowledgeDialog = (
		label: string,
		description: string,
		acknowledge: string,
	) =>
		this.dialog.open(
			new PolymorpheusComponent(AcknowledgeDialogComponent),
			AcknowledgeDialogComponent.Options({
				label,
				data: {
					description,
					acknowledge,
					translationParams: { media: this.media.name },
				},
			}),
		);

	readonly mediaArchiveDialog = () =>
		this.acknowledgeDialog(
			'media.archive-header',
			'media.archive-description',
			'media.archive-acknowledge',
		);

	readonly mediaUnarchiveDialog = () =>
		this.acknowledgeDialog(
			'media.unarchive-header',
			'media.unarchive-description',
			'media.unarchive-acknowledge',
		);

	readonly mediaDeleteDialog = () =>
		this.acknowledgeDialog(
			'media.delete-header',
			'media.delete-description',
			'media.delete-acknowledge',
		);

	readonly mediaDestroyDialog = () =>
		this.acknowledgeDialog(
			'media.destroy-header',
			'media.destroy-description',
			'media.destroy-acknowledge',
		);

	readonly mediaEditDetailDialog = () =>
		this.dialog.open(
			new PolymorpheusComponent(MediaEditDialogComponent),
			MediaEditDialogComponent.Options({
				data: { media: this.media, mediaRef: this.mediaRef },
			}),
		);

	ngOnInit() {
		if (!this.parent && this.mediaRef) {
			getDoc(
				this.mediaRef.parent.parent as DocumentReference<Content>,
			).then((ref) => {
				this.parent = ref.data() as Content;
			});
		}

		const now = new Date().valueOf();
		this.isArchived = !!(
			this.media.archivedAt &&
			this.media.archivedAt.toDate().valueOf() < now
		);

		if (this.access !== 'view') {
			this.setupMenuItems();
		}
	}

	setupMenuItems() {
		// some actions are unavailable when media is attached to a log
		this.menuItems = [...this.defaultMenuItems];

		if (!this.media.log) {
			if (this.isArchived) {
				this.menuItems.push({
					text: 'content.restore-from-archive',
					iconStart: '@tui.archive-restore',
					handler: () => this.onUnarchive(),
				});

				// we can only delete the folder if the user is an owner or greater
				const currentUser = this.authService.user as User;
				if (hasInheritedRolePermission(currentUser.role, 'owner')) {
					this.menuItems.push({
						text: 'media.delete-media',
						iconStart: '@tui.trash',
						handler: () => this.onDelete(),
					});
				}

				if (hasInheritedRolePermission(currentUser.role, 'super')) {
					this.menuItems.push({
						text: 'media.destroy-media',
						iconStart: '@tui.bomb',
						handler: () => this.onDestroy(),
					});
				}
			} else {
				this.menuItems.push(...this.activeMenuItems);
			}
		}

		// only can apply the thumbnail if we are in the context of the parent
		if (this.parent) {
			if (!this.isArchived && isMediaImage(this.media.type)) {
				if (this.media.setAsThumbnail) {
					this.menuItems.push({
						iconStart: '@tui.image-minus',
						text: 'media.action.remove-as-thumbnail',
						handler: () => this.onRemoveAsThumbnail(),
					});
				} else {
					this.menuItems.push({
						iconStart: '@tui.image-plus',
						text: 'media.action.use-as-thumbnail',
						handler: () => this.onUseAsThumbnail(),
					});
				}
			}
		} else {
			this.menuItems.push({
				iconEnd: '@tui.chevron-right',
				iconStart: '@tui.sprout',
				text: 'media.view-crop',
				handler: () => this.onGoToContent(),
			});
		}
	}

	onMenuOpen = () => this.mediaDetailMenu().subscribe();

	onPreviewLog = () => this.preview.emit();

	onGoToContent = () => this.router.navigate(['/home/file/', this.parent.id]);

	onUseAsThumbnail = () =>
		this.contentService
			.setContentMediaDerivedThumbnail({
				content: this.parent,
				media: this.media,
				derived: 'media',
				ref: this.mediaRef,
			})
			.then(() => {
				this.alert.open('generic-success').subscribe();
				this.menuOpen = false;
				this.update.emit();
			})
			.catch((error) => {
				console.error(error);
				this.alert.open('generic-error').subscribe();
				throw new Error(
					'There was an issue setting the content media thumbnail',
				);
			});

	onRemoveAsThumbnail = () =>
		this.contentService
			.checkAndRemoveContentMediaThumbnail({
				content: this.parent,
				mediaRef: this.mediaRef,
			})
			.then(() => {
				this.alert.open('generic-success').subscribe();
				this.menuOpen = false;
				this.update.emit();
			})
			.catch((error) => {
				console.error(error);
				this.alert.open('generic-error').subscribe();
				throw new Error(
					'There was an issue setting the content media thumbnail',
				);
			});

	async onDownload() {
		this.menuOpen = false;
		const ref = this.storageService.getReference(this.media.storagePath);
		const blob = await this.storageService.getBlob(ref);
		downloadBlob({ name: this.media.name, blob });
	}

	onEdit = () =>
		this.mediaEditDetailDialog().subscribe({
			next: (data) => {
				if (data) this.update.emit();
			},
		});

	onViewHistory = () => this.mediaActivityDialog().subscribe();

	onView = () => this.viewer.preview(this.media);

	onArchive = () =>
		this.mediaArchiveDialog().subscribe({
			next: (data) => {
				if (data) {
					this.mediaService
						.archive(this.parent, this.mediaRef)
						.then(() => {
							this.alert.open('generic-success').subscribe();
							this.update.emit();
						})
						.catch((error) => {
							this.alert.open('generic-error').subscribe();
							console.error(error);
							throw new Error(
								'There was an issue taking action on the media',
							);
						});
				}
			},
		});

	onUnarchive = () =>
		this.mediaUnarchiveDialog().subscribe({
			next: (data) => {
				if (data) {
					this.mediaService
						.unarchive(this.mediaRef)
						.then(() => {
							this.alert.open('generic-success').subscribe();
							this.update.emit();
						})
						.catch((error) => {
							this.alert.open('generic-error').subscribe();
							console.error(error);
							throw new Error('Could not unarchive the media');
						});
				}
			},
		});

	onDelete = () =>
		this.mediaDeleteDialog().subscribe({
			next: (data) => {
				if (data) {
					this.mediaService
						.delete(this.parent, this.mediaRef)
						.then(() => {
							this.alert.open('generic-success').subscribe();
							this.update.emit();
						})
						.catch((error) => {
							this.alert.open('generic-error').subscribe();
							console.error(error);
							throw new Error(
								'There was an issue deleting on the media',
							);
						});
				}
			},
		});

	/**
	 * @param force `true` to bypass the parent archived check
	 */
	onDestroy = () =>
		this.mediaDestroyDialog().subscribe({
			next: (data) => {
				if (data) {
					this.mediaService
						.delete(this.parent, this.mediaRef, { hard: true })
						.then(() => {
							this.alert.open('generic-success').subscribe();
							this.update.emit();
						})
						.catch((error) => {
							this.alert.open('generic-error').subscribe();
							console.error(error);
							throw new Error(
								'There was an issue destroying on the media',
							);
						});
				}
			},
		});
}
