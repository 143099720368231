import * as i0 from '@angular/core';
import { Directive, Input } from '@angular/core';
import { Validators, NG_VALIDATORS } from '@angular/forms';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk/constants';
import { tuiProvide } from '@taiga-ui/cdk/utils';
class TuiValidator {
  constructor() {
    this.onChange = EMPTY_FUNCTION;
    this.tuiValidator = Validators.nullValidator;
  }
  validate(control) {
    return this.tuiValidator(control);
  }
  registerOnValidatorChange(onChange) {
    this.onChange = onChange;
  }
  ngOnChanges() {
    this.onChange();
  }
  ngOnDestroy() {
    this.tuiValidator = Validators.nullValidator;
    this.onChange();
  }
  static {
    this.ɵfac = function TuiValidator_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiValidator)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiValidator,
      selectors: [["", "tuiValidator", ""]],
      inputs: {
        tuiValidator: "tuiValidator"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiProvide(NG_VALIDATORS, TuiValidator, true)]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiValidator, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiValidator]',
      providers: [tuiProvide(NG_VALIDATORS, TuiValidator, true)]
    }]
  }], null, {
    tuiValidator: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiValidator };
