import {
	ActivatedRouteSnapshot,
	BaseRouteReuseStrategy,
} from '@angular/router';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
	// all other methods will be the same as the default

	/*
	 * Determines if a route should be reused.
	 * This strategy returns `true` when the future route config and current route config are identical.
	 *
	 * but we want to refresh the route if the data.refreshComponent is true in the route definition
	 * */
	override shouldReuseRoute(
		next: ActivatedRouteSnapshot,
		current: ActivatedRouteSnapshot,
	): boolean {
		// so we need to check if it is true. if not, we return the default behavior
		return next.data['reload']
			? false
			: next.routeConfig === current.routeConfig;
	}
}
