import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
	Component,
	HostBinding,
	inject,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {
	Router,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
} from '@angular/router';
import {
	DestroyService,
	DialogService,
	HelpDialogComponent,
} from '@context/frontend/common';
import { ContentService } from '@context/frontend/content';
import { getUaDevice } from '@context/frontend/native/platform';
import { NotificationService } from '@context/frontend/notification';
import {
	AuthService,
	AvatarComponent,
	LogoutDialogComponent,
	RoleAccessPipe,
	UserNamePipe,
} from '@context/frontend/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiTabBar } from '@taiga-ui/addon-mobile';
import { TuiButton, TuiDialogContext, TuiIcon, TuiLink } from '@taiga-ui/core';
import { TuiBadgedContent, TuiBadgeNotification } from '@taiga-ui/kit';
import {
	PolymorpheusComponent,
	PolymorpheusTemplate
} from '@taiga-ui/polymorpheus';
import { map, Subscription } from 'rxjs';
import { MobileMenuDialogComponent } from '../../dialogs';
import { GlobalSearchBarComponent } from '../global-search-bar/global-search-bar.component';
import { GlobalSearchDialogComponent } from '../global-search-dialog/global-search-dialog.component';
import {
	EntityType,
	EntityTypeMenuItems,
	MenuItem,
	MenuItems,
} from './navigation.const';

@Component({
	standalone: true,
	imports: [
		RouterOutlet,
		TuiButton,
		TuiIcon,
		TranslocoModule,
		RouterLink,
		RouterLinkActive,
		TuiLink,
		RoleAccessPipe,
		AvatarComponent,
		UserNamePipe,
		TuiTabBar,
		TuiBadgedContent,
		TuiBadgeNotification,
		AsyncPipe,
		PolymorpheusTemplate,
		NgTemplateOutlet,
		GlobalSearchBarComponent,
	],
	selector: 'ctx-navigation-template',
	templateUrl: 'navigation-template.component.html',
	styleUrl: 'navigation-template.component.scss',
	providers: [DestroyService],
	encapsulation: ViewEncapsulation.None,
})
export class NavigationTemplateComponent {
	@HostBinding('class.collapsed')
	sidebarCollapsed = false;

	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@ViewChild('createTemplate')
	createTemplate?: TemplateRef<TuiDialogContext>;

	readonly authService = inject(AuthService);
	private readonly notificationService = inject(NotificationService);
	private readonly router = inject(Router);
	private readonly dialog = inject(DialogService);
	private readonly contentService = inject(ContentService);

	readonly entityTypes = EntityTypeMenuItems;
	readonly menuItems = MenuItems({
		createHandler: () => this.onPromptCreate(),
		logoutHandler: () => this.onPromptLogout(),
		notificationCount$: this.notificationService.unreadNotificationCount$
			.asObservable()
			.pipe(
				map((count) => {
					if (!count) return null;
					if (count >= 100) return `${count}+`;
					return `${count}`;
				}),
			),
	});

	readonly helpItem: MenuItem = {
		key: 'help',
		icon: 'circle-help',
		handler: () => this.onOpenHelp(),
		location: 'side',
	};

	createDialogSubscription: Subscription | null = null;

	readonly helpDialog = () =>
		this.dialog.open(
			new PolymorpheusComponent(HelpDialogComponent),
			HelpDialogComponent.Options({
				data: {
					user: this.authService.user,
					organization: this.authService.organization,
				},
			}),
		);

	readonly searchDialog = () =>
		this.dialog.open(
			new PolymorpheusComponent(GlobalSearchDialogComponent),
			GlobalSearchDialogComponent.Options(),
		);

	readonly logoutDialog = this.dialog.open<boolean>(
		new PolymorpheusComponent(LogoutDialogComponent),
		LogoutDialogComponent.Options(),
	);

	readonly mobileMenuDialog = (menuItems: MenuItem[]) =>
		this.dialog.open(
			new PolymorpheusComponent(MobileMenuDialogComponent),
			MobileMenuDialogComponent.Options({ data: { menuItems } }),
		);

	onMenuOpen = () =>
		this.mobileMenuDialog([...this.menuItems, this.helpItem]).subscribe();

	onOpenHelp = () => this.helpDialog().subscribe();

	onDismissLogout = (logout: boolean) =>
		logout ? this.router.navigate(['/auth/logout']) : {};

	onPromptLogout = () =>
		this.logoutDialog.subscribe({
			next: (data) => this.onDismissLogout(data),
		});

	onToggleSidebar() {
		this.sidebarCollapsed = !this.sidebarCollapsed;
	}

	onPromptCreate() {
		this.createDialogSubscription = this.dialog
			.open(this.createTemplate, {
				label: 'navigation.create',
				size: 's',
			})
			.subscribe({
				complete: () => {
					this.createDialogSubscription = null;
				},
			});
	}

	onCreate(entityType: EntityType) {
		const queryParams: any = {};
		const folderId = this.contentService.openFolderId.value;
		if (folderId) queryParams['folder'] = folderId;

		this.createDialogSubscription?.unsubscribe();
		switch (entityType) {
			case 'user':
				return this.router.navigate(['users', 'invite']);
			case 'file': {
				return this.router.navigate(['home', 'file', 'create'], {
					queryParams,
				});
			}
			case 'folder':
				return this.router.navigate(['home', 'folder', 'create'], {
					queryParams,
				});
			default:
				console.warn('Type not supported', entityType);
				return;
		}
	}

	onSearchOpen = () => this.searchDialog().subscribe();
}
