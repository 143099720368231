// builds/browser.ts
import { createXhrRequester } from "@algolia/requester-browser-xhr";
import { createBrowserLocalStorageCache, createFallbackableCache, createMemoryCache, createNullLogger, DEFAULT_CONNECT_TIMEOUT_BROWSER, DEFAULT_READ_TIMEOUT_BROWSER, DEFAULT_WRITE_TIMEOUT_BROWSER } from "@algolia/client-common";

// src/monitoringClient.ts
import { createAuth, createTransporter, getAlgoliaAgent } from "@algolia/client-common";
var apiClientVersion = "1.12.0";
function getDefaultHosts() {
  return [{
    url: "status.algolia.com",
    accept: "readWrite",
    protocol: "https"
  }];
}
function createMonitoringClient({
  appId: appIdOption,
  apiKey: apiKeyOption,
  authMode,
  algoliaAgents,
  ...options
}) {
  const auth = createAuth(appIdOption, apiKeyOption, authMode);
  const transporter = createTransporter({
    hosts: getDefaultHosts(),
    ...options,
    algoliaAgent: getAlgoliaAgent({
      algoliaAgents,
      client: "Monitoring",
      version: apiClientVersion
    }),
    baseHeaders: {
      "content-type": "text/plain",
      ...auth.headers(),
      ...options.baseHeaders
    },
    baseQueryParameters: {
      ...auth.queryParameters(),
      ...options.baseQueryParameters
    }
  });
  return {
    transporter,
    /**
     * The `appId` currently in use.
     */
    appId: appIdOption,
    /**
     * Clears the cache of the transporter for the `requestsCache` and `responsesCache` properties.
     */
    clearCache() {
      return Promise.all([transporter.requestsCache.clear(), transporter.responsesCache.clear()]).then(() => void 0);
    },
    /**
     * Get the value of the `algoliaAgent`, used by our libraries internally and telemetry system.
     */
    get _ua() {
      return transporter.algoliaAgent.value;
    },
    /**
     * Adds a `segment` to the `x-algolia-agent` sent with every requests.
     *
     * @param segment - The algolia agent (user-agent) segment to add.
     * @param version - The version of the agent.
     */
    addAlgoliaAgent(segment, version) {
      transporter.algoliaAgent.add({
        segment,
        version
      });
    },
    /**
     * Helper method to switch the API key used to authenticate the requests.
     *
     * @param params - Method params.
     * @param params.apiKey - The new API Key to use.
     */
    setClientApiKey({
      apiKey
    }) {
      if (!authMode || authMode === "WithinHeaders") {
        transporter.baseHeaders["x-algolia-api-key"] = apiKey;
      } else {
        transporter.baseQueryParameters["x-algolia-api-key"] = apiKey;
      }
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customDelete - The customDelete object.
     * @param customDelete.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customDelete.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customDelete({
      path,
      parameters
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customDelete`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customGet - The customGet object.
     * @param customGet.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customGet.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customGet({
      path,
      parameters
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customGet`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPost - The customPost object.
     * @param customPost.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPost.parameters - Query parameters to apply to the current query.
     * @param customPost.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPost({
      path,
      parameters,
      body
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPost`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPut - The customPut object.
     * @param customPut.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPut.parameters - Query parameters to apply to the current query.
     * @param customPut.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPut({
      path,
      parameters,
      body
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPut`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves known incidents for the selected clusters.
     * @param getClusterIncidents - The getClusterIncidents object.
     * @param getClusterIncidents.clusters - Subset of clusters, separated by commas.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getClusterIncidents({
      clusters
    }, requestOptions) {
      if (!clusters) {
        throw new Error("Parameter `clusters` is required when calling `getClusterIncidents`.");
      }
      const requestPath = "/1/incidents/{clusters}".replace("{clusters}", encodeURIComponent(clusters));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the status of selected clusters.
     * @param getClusterStatus - The getClusterStatus object.
     * @param getClusterStatus.clusters - Subset of clusters, separated by commas.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getClusterStatus({
      clusters
    }, requestOptions) {
      if (!clusters) {
        throw new Error("Parameter `clusters` is required when calling `getClusterStatus`.");
      }
      const requestPath = "/1/status/{clusters}".replace("{clusters}", encodeURIComponent(clusters));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves known incidents for all clusters.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getIncidents(requestOptions) {
      const requestPath = "/1/incidents";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves average times for indexing operations for selected clusters.
     * @param getIndexingTime - The getIndexingTime object.
     * @param getIndexingTime.clusters - Subset of clusters, separated by commas.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getIndexingTime({
      clusters
    }, requestOptions) {
      if (!clusters) {
        throw new Error("Parameter `clusters` is required when calling `getIndexingTime`.");
      }
      const requestPath = "/1/indexing/{clusters}".replace("{clusters}", encodeURIComponent(clusters));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the average latency for search requests for selected clusters.
     * @param getLatency - The getLatency object.
     * @param getLatency.clusters - Subset of clusters, separated by commas.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getLatency({
      clusters
    }, requestOptions) {
      if (!clusters) {
        throw new Error("Parameter `clusters` is required when calling `getLatency`.");
      }
      const requestPath = "/1/latency/{clusters}".replace("{clusters}", encodeURIComponent(clusters));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves metrics related to your Algolia infrastructure, aggregated over a selected time window.  Access to this API is available as part of the [Premium or Elevate plans](https://www.algolia.com/pricing). You must authenticate requests with the `x-algolia-application-id` and `x-algolia-api-key` headers (using the Monitoring API key).
     * @param getMetrics - The getMetrics object.
     * @param getMetrics.metric - Metric to report.  For more information about the individual metrics, see the description of the API response. To include all metrics, use `*`.
     * @param getMetrics.period - Period over which to aggregate the metrics:  - `minute`. Aggregate the last minute. 1 data point per 10 seconds. - `hour`. Aggregate the last hour. 1 data point per minute. - `day`. Aggregate the last day. 1 data point per 10 minutes. - `week`. Aggregate the last week. 1 data point per hour. - `month`. Aggregate the last month. 1 data point per day.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getMetrics({
      metric,
      period
    }, requestOptions) {
      if (!metric) {
        throw new Error("Parameter `metric` is required when calling `getMetrics`.");
      }
      if (!period) {
        throw new Error("Parameter `period` is required when calling `getMetrics`.");
      }
      const requestPath = "/1/infrastructure/{metric}/period/{period}".replace("{metric}", encodeURIComponent(metric)).replace("{period}", encodeURIComponent(period));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Test whether clusters are reachable or not.
     * @param getReachability - The getReachability object.
     * @param getReachability.clusters - Subset of clusters, separated by commas.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getReachability({
      clusters
    }, requestOptions) {
      if (!clusters) {
        throw new Error("Parameter `clusters` is required when calling `getReachability`.");
      }
      const requestPath = "/1/reachability/{clusters}/probes".replace("{clusters}", encodeURIComponent(clusters));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the servers that belong to clusters.  The response depends on whether you authenticate your API request:  - With authentication, the response lists the servers assigned to your Algolia application\'s cluster.  - Without authentication, the response lists the servers for all Algolia clusters.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getServers(requestOptions) {
      const requestPath = "/1/inventory/servers";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the status of all Algolia clusters and instances.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getStatus(requestOptions) {
      const requestPath = "/1/status";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    }
  };
}

// builds/browser.ts
function monitoringClient(appId, apiKey, options) {
  if (!appId || typeof appId !== "string") {
    throw new Error("`appId` is missing.");
  }
  if (!apiKey || typeof apiKey !== "string") {
    throw new Error("`apiKey` is missing.");
  }
  return createMonitoringClient({
    appId,
    apiKey,
    timeouts: {
      connect: DEFAULT_CONNECT_TIMEOUT_BROWSER,
      read: DEFAULT_READ_TIMEOUT_BROWSER,
      write: DEFAULT_WRITE_TIMEOUT_BROWSER
    },
    logger: createNullLogger(),
    requester: createXhrRequester(),
    algoliaAgents: [{
      segment: "Browser"
    }],
    authMode: "WithinQueryParameters",
    responsesCache: createMemoryCache(),
    requestsCache: createMemoryCache({
      serializable: false
    }),
    hostsCache: createFallbackableCache({
      caches: [createBrowserLocalStorageCache({
        key: `${apiClientVersion}-${appId}`
      }), createMemoryCache()]
    }),
    ...options
  });
}
export { apiClientVersion, monitoringClient };
