import { inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StorageService } from '@context/frontend/storage';
import {
	ContentMedia,
	getExtension,
	isMediaDoc,
	isMediaImage,
	isMediaPdf,
} from '@context/shared/types/media';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { TuiPdfViewerService, TuiPreviewDialogService } from '@taiga-ui/kit';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import {
	DocumentPreviewDialogComponent,
	ImagePreviewDialogComponent,
} from '../dialogs';

const GoogleDocViewerUrl = 'https://docs.google.com/gview?embedded=true&url=';

@Injectable({ providedIn: 'root' })
export class ViewerService {
	readonly previewDialog = inject(TuiPreviewDialogService);
	readonly storageService = inject(StorageService);
	readonly pdf = inject(TuiPdfViewerService);
	readonly sanitizer = inject(DomSanitizer);
	readonly isMobile = inject(TUI_IS_MOBILE);

	readonly imagePreviewDialog = (content: ContentMedia) =>
		this.previewDialog.open(
			new PolymorpheusComponent(ImagePreviewDialogComponent),
			ImagePreviewDialogComponent.Options({
				data: { content },
			}),
		);

	readonly docPreviewDialog = (safeUrl: SafeUrl, label: string) =>
		this.previewDialog.open(
			new PolymorpheusComponent(DocumentPreviewDialogComponent),
			DocumentPreviewDialogComponent.Options({
				data: { safeUrl, label },
			}),
		);

	preview(media: ContentMedia) {
		const type = this.getViewType(media);
		switch (type) {
			case 'image':
				return this.imagePreviewDialog(media).subscribe();
			case 'pdf':
				return this.openPdf(media);
			case 'doc':
				return this.safeUrl(media).then((url) =>
					this.docPreviewDialog(url, media.name).subscribe(),
				);
			case 'unsupported':
			default:
				// implement actual user UI error
				throw new Error('Media is not supported');
		}
	}

	async openPdf(media: ContentMedia) {
		const url = await this.storageService.getDownloadUrl(
			this.storageService.getReference(media.storagePath),
		);

		// the taiga PDF viewer doesn't work on mobile, so just using browser viewers
		if (this.isMobile) return window.open(url, '_blank');

		const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		return this.pdf.open(safeUrl, { label: media.name }).subscribe();
	}

	safeUrl = (media: ContentMedia) =>
		this.storageService
			.getDownloadUrl(this.storageService.getReference(media.storagePath))
			.then((url) => {
				const value = `${GoogleDocViewerUrl}${encodeURIComponent(url)}`;
				return this.sanitizer.bypassSecurityTrustResourceUrl(value);
			});

	getViewType(media: ContentMedia) {
		if (isMediaImage(media.type)) return 'image';
		if (isMediaPdf(media.type)) return 'pdf';
		if (isMediaDoc(getExtension(media.name))) return 'doc';
		return 'unsupported';
	}
}
