import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { StorageService } from '@context/frontend/storage';
import {
	ContentMedia,
	getExtension,
	getMediaIcon,
} from '@context/shared/types/media';
import {
	TuiFallbackSrcPipe,
	TuiHint,
	TuiIcon,
	TuiIconPipe,
} from '@taiga-ui/core';
import { TuiAvatar } from '@taiga-ui/kit';

@Component({
	standalone: true,
	imports: [
		TuiAvatar,
		TuiIcon,
		TuiIconPipe,
		TuiFallbackSrcPipe,
		AsyncPipe,
		TuiHint,
	],
	selector: 'ctx-media-thumbnail',
	templateUrl: 'media-thumbnail.component.html',
	styleUrl: 'media-thumbnail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaThumbnailComponent implements OnInit {
	@Input()
	media!: ContentMedia;

	@Input()
	badgeIcon: string | null = null;

	@Input()
	badgeHint: string | null = null;

	readonly storageService = inject(StorageService);
	readonly cdRef = inject(ChangeDetectorRef);

	thumbnailUrl: string | null = null;

	get icon() {
		const ext = getExtension(this.media.name);
		return getMediaIcon(ext.slice(1));
	}

	async ngOnInit() {
		if (this.media.thumbnailPath) {
			const ref = this.storageService.getReference(
				this.media.thumbnailPath,
			);
			this.thumbnailUrl = await this.storageService.getDownloadUrl(ref);
			this.cdRef.detectChanges();
		}
	}
}
