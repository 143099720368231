import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { applyValidators } from '@context/frontend/form';
import { Content } from '@context/shared/types/common';
import { ContentMedia } from '@context/shared/types/media';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiItem } from '@taiga-ui/cdk/directives/item';
import { TuiButton, TuiError, TuiIcon } from '@taiga-ui/core';
import {
	TuiButtonLoading,
	TuiFieldErrorPipe,
	TuiFileLike,
	TuiFiles,
	TuiInputFiles,
} from '@taiga-ui/kit';
import { DocumentReference } from 'firebase/firestore';
import { MediaService } from '../../services';

type FileState = 'loading' | 'normal' | 'error';

@Component({
	standalone: true,
	imports: [
		TuiFiles,
		TuiInputFiles,
		TuiItem,
		TuiIcon,
		TuiButton,
		ReactiveFormsModule,
		TuiFieldErrorPipe,
		AsyncPipe,
		TuiError,
		TranslocoModule,
		TuiButtonLoading,
	],
	selector: 'ctx-media-upload-dialog',
	templateUrl: 'media-upload-dialog.component.html',
})
export class MediaUploadDialogComponent extends applyValidators(
	DialogBase<ContentMedia[], { parent: DocumentReference<Content> }>,
) {
	static override readonly DialogOptions = {
		label: 'media.upload-files',
		dismissible: false,
	} as DialogOptions;

	readonly mediaService = inject(MediaService);

	readonly form = new FormGroup({
		files: new FormControl<TuiFileLike[]>(
			[],
			[(field) => this.getMaxLengthValidator(field, 10)],
		),
	});

	readonly fileStateMap = new Map<TuiFileLike, FileState>();

	busy = false;

	onRemove(file: TuiFileLike) {
		this.form
			.get('files')
			?.setValue(
				this.form
					.get('files')
					?.value?.filter((current) => current !== file) ?? [],
			);
	}

	async onUpload() {
		this.form.markAllAsTouched();
		if (this.busy || this.form.invalid) return;
		this.busy = true;
		this.form.disable();

		const files = this.form.get('files')?.value;
		if (!files?.length) this.onDismiss();

		let hasError = false;
		const batch: Promise<ContentMedia | null>[] = [];
		files?.forEach((file) => {
			// if the user is reuploading failed files, then we want to
			// prevent them from creating the same file more than once
			if (this.fileStateMap.get(file) !== 'normal') {
				this.fileStateMap.set(file, 'loading');
				batch.push(
					this.mediaService
						.create(
							this.context.data
								.parent as DocumentReference<Content>,
							file as File,
						)
						.then((res) => {
							this.fileStateMap.set(file, 'normal');
							return res.data;
						})
						.catch((error) => {
							console.error(
								'There was an issue uploading the media',
								error,
							);
							hasError = true;
							this.fileStateMap.set(file, 'error');
							return null;
						}),
				);
			}
		});

		const media = await Promise.all(batch).catch((error) => {
			this.busy = false;
			console.error(error);
			throw new Error('There was an issue resolving all of the requests');
		});

		if (hasError) {
			this.busy = false;
			this.form.enable();
		} else {
			// wait a second to allow the file status to complete
			setTimeout(() => {
				this.onDismiss(media.filter((m) => !!m));
			}, 1000);
		}
	}
}
