import * as i0 from '@angular/core';
import { inject, Injectable, Directive } from '@angular/core';
import { tuiTypedFromEvent, tuiPreventDefault } from '@taiga-ui/cdk/observables';
import { tuiCreateToken, tuiInjectElement, tuiDistanceBetweenTouches } from '@taiga-ui/cdk/utils';
import { Observable, merge, filter, switchMap, scan, map, takeUntil } from 'rxjs';

/**
 * Zoom options
 */
const TUI_ZOOM_OPTIONS = tuiCreateToken({
  wheelSensitivity: 0.01
});
const TOUCH_SENSITIVITY = 0.01;
class TuiZoomService extends Observable {
  constructor() {
    const el = tuiInjectElement();
    const {
      wheelSensitivity
    } = inject(TUI_ZOOM_OPTIONS);
    super(subscriber => merge(tuiTypedFromEvent(el, 'touchstart', {
      passive: true
    }).pipe(filter(({
      touches
    }) => touches.length > 1), switchMap(startEvent => tuiTypedFromEvent(el, 'touchmove', {
      passive: true
    }).pipe(tuiPreventDefault(), scan((prev, event) => {
      const distance = tuiDistanceBetweenTouches(event);
      return {
        event,
        distance,
        delta: (distance - prev.distance) * TOUCH_SENSITIVITY
      };
    }, {
      event: startEvent,
      distance: tuiDistanceBetweenTouches(startEvent),
      delta: 0
    }), map(({
      event,
      delta
    }) => {
      const clientX = ((event.touches[0]?.clientX ?? 0) + (event.touches[1]?.clientX ?? 0)) / 2;
      const clientY = ((event.touches[0]?.clientY ?? 0) + (event.touches[1]?.clientY ?? 0)) / 2;
      return {
        clientX,
        clientY,
        delta,
        event
      };
    }), takeUntil(tuiTypedFromEvent(el, 'touchend'))))), tuiTypedFromEvent(el, 'wheel', {
      passive: false
    }).pipe(tuiPreventDefault(), map(wheel => ({
      clientX: wheel.clientX,
      clientY: wheel.clientY,
      delta: -wheel.deltaY * wheelSensitivity,
      event: wheel
    })))).subscribe(subscriber));
  }
  static {
    this.ɵfac = function TuiZoomService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiZoomService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiZoomService,
      factory: TuiZoomService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiZoomService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class TuiZoom {
  constructor() {
    this.tuiZoom = inject(TuiZoomService);
  }
  static {
    this.ɵfac = function TuiZoom_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiZoom)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiZoom,
      selectors: [["", "tuiZoom", ""]],
      hostVars: 2,
      hostBindings: function TuiZoom_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("touch-action", "none");
        }
      },
      outputs: {
        tuiZoom: "tuiZoom"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiZoomService])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiZoom, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiZoom]',
      outputs: ['tuiZoom'],
      providers: [TuiZoomService],
      host: {
        '[style.touch-action]': '"none"'
      }
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ZOOM_OPTIONS, TuiZoom, TuiZoomService };
