import { Component, inject, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { DestroyService } from '@context/frontend/common';
import { GlobalSearchService } from '@context/frontend/search';
import { AlgoliaGlobal } from '@context/shared/types/search';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiDropdown, TuiTextfield } from '@taiga-ui/core';
import { TuiSearchComponent } from '@taiga-ui/layout';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalSearchDropdownComponent } from './global-search-dropdown/global-search-dropdown.component';

@Component({
	standalone: true,
	imports: [
		TuiSearchComponent,
		ReactiveFormsModule,
		FormsModule,
		TranslocoModule,
		TuiTextfield,
		GlobalSearchDropdownComponent,
		TuiDropdown,
	],
	selector: 'ctx-global-search-bar',
	templateUrl: 'global-search-bar.component.html',
	styleUrl: 'global-search-bar.component.scss',
	providers: [DestroyService],
})
export class GlobalSearchBarComponent implements OnInit {
	readonly search = new FormGroup({
		term: new FormControl(''),
	});

	readonly destroy$ = inject(DestroyService);
	readonly searchService = inject(GlobalSearchService);

	dropdownOpen = false;

	loading = false;
	data: AlgoliaGlobal[] = [];
	totalElements = 0;

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		this.search
			.get('term')
			?.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
			.subscribe((term) => {
				if (!term) {
					this.dropdownOpen = false;
					return;
				}

				this.fetchData(term);
			});
	}

	fetchData(term: string) {
		this.loading = true;
		this.searchService
			.fetch(term)
			.then((res: any) => {
				this.totalElements = res.nbHits ?? 0;
				this.data = res.hits ?? [];
			})
			.finally(() => {
				this.loading = false;
			});
	}

	onClose() {
		this.dropdownOpen = false;
		this.search.get('term')?.setValue(null);
	}
}
